@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.cart-seller-info-data-btns {
  .delete-btn {
    margin-top: .5rem;
    @include from(448px) {
      margin-left: 2rem;
    }
  }
}

.delete-btn {
  margin-left: 1rem;
}
