@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-notFound {
  position: relative;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem auto 0;
  display: block;
  img {
    
    max-width: 100%;
    max-height: 100%;
  }
}


