.abs-center {

    width: 95%;
    margin: 1rem auto 2rem;
    max-width: 600px;
    padding: 30px 20px;
}
.container-logo-verify  {
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.container-logo-verify img {
  text-align: center;
  margin: 10px auto;
}
