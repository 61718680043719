@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-container-btn-google-login {
  .google-container {
    // background-color: green !important;
    button {
      width: 100% !important;
      margin: 0 0 20px !important;
      min-width: 100% !important;
      // display: block !important;
    }
  }
  
}


.btn-login {
  background-color: $secondColor;
  color: white;
}

.link-register {
  text-align: center;
  color: $firstColor;
}

.privacy-google {
  width: 100%;
  max-width: 360px;
  margin: 1rem auto 0;
  display: block;
  text-align: center;
  font-size: var(--body-font-size);
  line-height: 15px;
  // background-color: red;
  a {
    text-decoration: underline;
  }
}

.privacy-google-welcome {
  align-self: flex-end;
  padding: .5rem 1rem;
  color: #eee;
  font-size: var(--small-font-size);
  @include from(420px) {
    font-size: .85em;
    padding: 0;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    color: $secondColor;
  }
}
