@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.main-welcome {
  position: relative;
  height: 100%;
  // background: url(logotipo) no-repeat scroll center bottom / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    z-index: $z-normal;
    background: $firstColor;
    background: linear-gradient(140deg, $secondColor 20%, $firstColor 110%);
  }
}

.main-welcome-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main-welcome-logo {
  width: 230px;
  height: 100px;
//   margin-top: 10rem;
}
.main-welcome--content {
  position: relative;
  z-index: $z-tooltip;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-welcome-btn {
  text-align: center;
  margin-top: 4rem;
}
.main-welcome--ctaRegister {
  display: block;
  .cta-register {
    // background-color: $firstColor;
    // color: $textColor;
    // border-radius: 30px;
    // border: none;
    // padding: 0 4rem;
    color: white;
    &:hover {
      background-color: $secondColor;
    }
  }
}
.main-welcome--ctaLogin {
  font-size: var(--body-font-size);
  margin-top: 1rem;
  color: white;
  a {
    font-weight: bold;
    color: white;
    &:hover {
      color: $firstColor;
    }
  }

  &.faq {
    margin-top: -1.5rem;
  }
}

.cta-register {
  padding: 0 4rem !important;
}


.container-btn-google-login {
  width: 100%;
  // background-color: green;
  display: flex;
  justify-content: center; 
  align-items: center;
  font-family: 'Nunito', sans-serif;
}

.container-spiner-google {
  width: 100% !important;
  
  margin: 0 auto; 
  display: flex;
  justify-content: center; 
  align-items: center;
}
.btn-google-login {
  width: 100% !important;
  max-height: 40px !important;
  min-width: 208px !important;
  max-width: 208px !important;
  margin: .5rem auto .8rem;
  text-align: center !important;
  border-radius: $border-radius-button !important;
  border: 1px solid rgba(0,0,0,.1) !important;
  justify-content: center !important;
  transition: all .3s;
  box-shadow: $box-shadow-box !important;
  &:hover {
    box-shadow:0 0 10px 0 rgba(0,0,0,.5);
  }
  div {
    margin-right: 0 !important;
    padding: 0 0 0 10px !important;
  }
  div, svg {
    max-height: 40px !important;
    margin-top: 2px;
  }
  svg {
    position: relative;
    top: -2px;
    left: -1px;
  }
  span {
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: bold !important;
    font-size: var(--small-font-size) !important;
    padding: 2px 10px 0 5px !important;
  }
}
.welcome-faq {
  color: white;
  text-decoration: underline;
  font-size: 0.8rem;
  font-weight: 400 !important;
}
