@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.container-PricingTable {
  margin-top: 1.5rem;
  padding: 1rem 1rem 0;
}

.subtitle-plan {
  font-size: 21px;
  font-weight: bold !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
  &.list {
    margin-left: -1rem;
  }
}

.main-list-contribution {
  padding: 2rem;
  @include from(m) {
    padding: 0 2rem 4rem;
    max-width: 700px;
    margin: 0 auto;
  }
}

.list-contribution {
  padding-left: 0;
  li {
    list-style-type: disc;
    font-weight: 600 !important;
  }
}

.modal-pricing {
  max-width: 600px !important;
  // background-color: yellow !important;
}
.center {
    margin: 0 auto;
}
.plan-heading {
    font-size: 2rem;
}
.plan-price {
    font-size: 1.6rem;
    span {
      font-size: var(--body-font-size);
    }
}
.plan-icon {
    font-size: 75px;
}
.back-btn {
    display: block;
    margin: 0 auto;
    width: 150px;
    margin-bottom: 15px;
}
.row {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}
.btn-pricing-primary {
  background-color: $secondColor;
  margin: 0 auto;
}

.card.card-body-two {
  background-color: $secondColor !important;
}

.main-card-body {
  min-width: 400px;
  max-width: 600px;
}

.card-PricingTable {
  // background-color: red;
  // box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  border-radius: 30px !important;
  margin: 10px;
  button {
    width: 200px;
    @include from(992px) {
      width: 100%;
      max-width: 250px;
    }
    height: 40px;
  }
}

// .card-body-pricing-PricingTable {
//   // background-color: turquoise;
//   // height: 300px !important;
// }
.card-body  {
  
  @include from(880px) {
    padding: 0 !important;
    text-align: center !important;
  }
}

.card-body-content {
  @include from(992px) {
    padding: 1rem 2rem !important;
  }
}

.list-unstyled {
  @include from(992px) {
    min-height: 100px !important;
  }
  
}

.btn-close-pricing {
  margin-bottom: 0px !important;
}
