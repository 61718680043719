@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$dimension-img-movil: 85px;
$dimension-img: 120px;

$height-movil: 110px; 
$height: 150px; 

$responsive: 860px; 

.main-container-store-info {
  width: 95%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}


.container-store-info {
  width: 100%;
  height: 100%;
  max-width: $max-width;
  margin: 0 auto;
  position: relative;
  height: $height-movil;
  @include from($responsive) {
    height: $height;
  }
}

// foto de portada
.container-store-portrait-picture {
  box-shadow: 0px 2px 4px -2px rgba(0,0,0,0.25);
  border-top-left-radius: $border-radius-box;
  border-top-right-radius: $border-radius-box;
  height: $height-movil;
  @include from($responsive) {
    height: $height;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
    border-top-left-radius: $border-radius-box;
    border-top-right-radius: $border-radius-box;
  }
}

// foto de perfil
.container-store-profile-picture {
  background: linear-gradient(140deg, $secondColor 20%, $firstColor 110%);
  position: absolute;
  left: 50%;
  right: 50%;
  top: 68%;
  transform: translate(-50%, -50%);
  width: $dimension-img-movil;
  height: $dimension-img-movil;
  border-radius: 50%;
  @include from($responsive) {
    left: 100px;
    top: 100%;
    transform: translate(-50%, -50%);
    width: $dimension-img;
    height: $dimension-img;
  }
  img {
    padding: 5px;
    width: $dimension-img-movil;
    height: $dimension-img-movil;
    border-radius: 50%;
    @include from($responsive) {
      width: $dimension-img;
      height: $dimension-img;
    }
  }
}


// Contenido

.container-store-description {
  width: 100%;
  border-bottom-left-radius: $border-radius-box;
  border-bottom-right-radius: $border-radius-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .8rem;
  @include from(420px) {
    padding: 1rem;
  }
  @include from(620px) {
    padding: 1.3rem;
  }
  @include from($responsive) {
    padding-left: 12rem;
  }

  .ant-rate {
    .ant-rate-star  {
      margin-right: 4px !important;
    }
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
}


.container-store-description-location {
  font-size: var(--body-font-size);
  &.movil {
    font-size: var(--small-font-size);
    margin-bottom: .1rem;
    @include from($responsive) {
      display: none;
    }
  }
}
.container-store-description-name {
  font-size: .9rem;
  font-weight: bold;
  margin-bottom: .3rem;
  span {
   font-weight: 300;
  }
  @include from(420px) {
      font-size: 1.15rem;
  }
  @include from(m) {
      font-size: 1.25rem;
  }
  @include from(720px) {
    font-size: 1.35rem;
  }
  
}

.container-store-description-category {
    font-size: .8rem;
    font-weight: 300;
    line-height: 17px;
    margin-bottom: .1rem;
    // text-align: center;
    @include from(560px) {
      font-size: var(--small-font-size);
    }
    @include from(620px) {
       font-size: var(--small-font-size);
      padding-right: 1rem;
    }
}

.p2-desktop-container-location {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
    margin-right: .5rem;
  }
}

.container-store-container-icon-movil {
  position: absolute;
  top: 10px;
  right: 10px;
  @include from($responsive) {
    display: none;
  }
}

.container-store-container-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    padding: 5px;
    background-color: $secondColor;
    margin-left: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }
  svg {
    color: white;
  }
}

.icon-container-movil {
  // width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.p2-movil {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // background-color: red;
  @include from($responsive) {
    display: none;
  }
  button {
    &:first-child {
      margin-bottom: 5px;
    }
  }
}



.btn-WhatsApp {
  background-color:#25D366;
  color: #eee;
  border: 1px solid #25D366;
  span {
    font-size: var(--small-font-size) !important;
  }
  svg {
    font-weight: bold !important;
    width: 16px !important;
    height: 16px !important;
    &:hover {
      background-color: transparent;
      color: #25D366 ;
    }
  }
  &:hover {
    background-color: transparent ;
    color: #25D366 ;
    border: 1px solid #25D366;
  }
}

.btn-description {
  background-color: transparent;
  border: 1px solid $secondColor;
  color: $secondColor;
  span {
    font-size: var(--small-font-size) !important;
  }
  svg {
    font-weight: bold !important;
  }
}

.p2-desktop {
  display: none; 
  @include from($responsive) {
    display: block;
    // background-color: green;
  }
}

.p2-desktop-container-btns {
  min-width:auto;
  display: flex;
  justify-content: space-between;
  // background-color: red;
  position: relative;
    div:nth-child(1n) {
      color: green;
      position: absolute;
      top: 0;
      left: -140px;
    }  
}


// drawer

.drawer-store {
  
  .ant-drawer-body {
    font-size: var(--small-font-size);
    // background-color: red !important;
   @include from(m) {
    font-size: var(--body-font-size);
   }
  }

}
.more-info-panel-text {
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.10);
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: .7rem 1rem .7rem 2.3rem;
  margin: 0 !important;
  p span {
    text-align: center;
  }
  svg {
    color: $secondColor !important;
    margin-right: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 13px;
}
}

.hover-blue:hover {
  background-color: $secondColor;
  color: white;
}
.more-info-bold {
  font-weight: 600;
}
