@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.home-title {
  text-align: center;
  color: gray;
  margin-top: 15px;
}
.cat-title {
  color: $secondColor;
  text-align: center;
  margin: 20px;
}
.home-map-settings-container {
  width: 100%;
  background-color: white;

}
.home-map-settings {
  background-color: white;
  padding: 25px .5rem;
  text-align: center;
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
  @include from(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px .5rem;
  }
}
.btn-map {
  margin-top: 10px;
  @include from(m) {
    margin-top: 0;
  }
}
.home-select-margin {
  margin-left: 10px;
}
@media (max-width: 600px) {
  .home-select-margin {
    margin-left: 0;
  }
}

.container-btn-home {
  width: 100%;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .btn-home-settings {
    font-size: .65rem !important;
    padding: .5rem .5rem !important;
    @include from(370px) {
      font-size: var(--small-font-size) !important;
    }
    @include from(420px) {
      font-size: .8rem !important;
      padding: .5rem 1.3rem !important;
    }
  }
  .btn-home-go-to-map {
    font-size: .65rem !important;
    padding: .5rem .5rem !important;
    @include from(370px) {
      font-size: var(--small-font-size) !important;
    }
    @include from(420px) {
      font-size: .8rem !important;
      padding: .5rem 1.3rem !important;
    }
    margin: 0;
  }
}

.map-select-categories-form {
  // background-color: red !important;

  h4 {
    font-size: 1.2rem !important;
  }
  h6 {
    font-size: var(--body-font-size) !important;
  }
  span, label,p {
    font-size: var(--small-font-size);
  }
}
