.form-profilePicture-person {
  // width: 95%;
  margin: 1.5rem auto 0;
  form {
    padding: 2rem;
  }
}

.title-profilePicture {
  padding-top: 2rem;
}
