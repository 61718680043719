@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";



// .box-product {
//   box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
//   border: 1px solid rgba(0,0,0,.1);
//   border-radius: 10px;
//   background-color: white;
//   border-radius:10px !important;
// }

.name-product, .description-product {
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description-product {
  font-size: .9rem;
  @include from(600px) {
    font-size: 1.1rem;
  }
  @include from(780px) {
    font-size: 1.25rem;
  }
}

.name-product {
  text-align: center;
  font-size: .8rem;
  margin-bottom: .3rem;
  
  @include from(600px) {
    font-size: var(--body-font-size);
  }
  // @include from(780px) {
  //   font-size: 1.25rem;
  // }
}
.product-price, .description-product {
  text-align: center;
  // font-size: .9rem;
  margin-bottom: .1rem;
  @include from(600px) {
    font-size: 0var(--body-font-size);;
  }
  // @include from(780px) {
  //   font-size: 1rem;
  // }
}

.product-price {
  // font-size: 1.3rem;
  // margin-bottom: 1rem;
  // min-height: 40px;
  font-size: .6rem;
  text-align: center;

  @include from(460px) {
    // min-height: 60px;
    font-size: .7rem;
  }
  @include from(600px) {
    // min-height: 60px;
    font-size: var(--small-font-size);
  }
  @include from(980px) {
    // min-height: 40px;
    font-size: var(--small-font-size);
  }
}

.product-price-space {
  margin-bottom: 15px;
}

.btn-add-to-cart {
  max-width: 70px;
  font-size: .65rem;
  padding-left: .4rem; 
  margin-top: 5px;
  svg {
    margin-left: -8px;
    margin-top: -2px;
  }
  @include from(m) {
    max-width: 160px;
    font-size: var(--body-font-size);
  }
  margin-bottom: .8rem;
}

// Icono de agregado al carrito

.container-add-to-cart {
  width: 100%;
  // height: 40px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: .8rem;
}

.main-empty-store-product {
  width: 95%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}

.box-product-description-container {
  padding: .7rem .7rem 0;
}


.btn-cart-movil {
  width: 100%;
  // display: block;
  @include from(m) {
    display: none;
  }
  button {
    width: 100%;
    display: flex;
    align-items: center;
    &::after {
      content: '+' !important;
      color: white !important;
      font-size: 1.15rem;
      position: absolute;
      top: 2px;
      left: 40px;
    }
    span {
      line-height: 30px !important;
      position: relative !important;
      
      svg {
        position: relative;
        top: -1px;
        left: 10px;
      }
      
    }
  }
}

.btn-cart-desktop {
  width: 100%;
  display: none;
  button {
    span {
      svg {
        position: relative;
        top: -1.5px;
        left: 4px;
      }
    }
  }
  @include from(m) {
    display: block;
  }
}


.main-empty-store-product {
  margin-top: 1rem;
}
