// Colores de propT1
$textColor: #212529;
$titleColor: #212529;
$firstColor: #9BB908;
$secondColor: #006BB6;
$iconColor: #161B4B;
$headerHeight: 70px;
$max-width: 1170px;
$max-with-form: 600px;
$border-radius-box: 8px;
$border-radius-input: 8px;
$border-radius-button: 8px;
$box-shadow-box: 0 0 10px 0 rgba(0,0,0,.05);

// Estos son los breakpoints para las medias queries, (Cambiar si quieren, yo coloque los estandar)
$breakPoints: (
  s: 320px,
  m: 640px,
  l: 1024px,
  xl: 1400px,
);

// Variables de apilamineto z-index
$z-back: -10;
$z-normal: 1;
$z-tooltip: 10;
$z-fixed: 100;
$z-modal: 1000;

// Varialbles de tipografia
:root {
  --h1-font-size: 1.4rem;
  --h2-font-size: 1.2rem;
  --h3-font-size: 1.35rem;
  --body-font-size: .875rem;
  --small-font-size: .75rem;

  // Cambiar por los colores de propT1
  --darkColor: #282d31;
  --textColor: #53575a;
  --borderColor: #dfe0e0;
  --firstColor: #a5c2da;
  --secondColor: #ff3c32;
  --lightColor: #fff;

  @include from(375px) {
    --body-font-size: 1rem;
    --small-font-size: .875rem;
  }
  @include from(m) {
    --h1-font-size: 1.8rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.35rem;
    // --body-font-size: 1rem;
    // --small-font-size: 0.875rem;
  }

  @include from(l) {
    --h1-font-size: 2rem;
    --h2-font-size: 1.8rem;
    --h3-font-size: 1.6rem;
    // --body-font-size: 1rem;
    // --small-font-size: 0.875rem;
  }
}
