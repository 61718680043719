@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.btn-editStore {
  padding: 0 2rem;
}

.profile-store-title {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0;
  color: $secondColor;
} 
.profile-store {
  padding: 0 .5rem;
}

.card-store {
  margin-top: 1.5rem;
}

.container-description-store {
  // background-color: pink;
  text-align: left;
  @include from(690px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include from(830px) {
    display: block
  }
  @include from(1040px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}

.message-bloqued {
  margin: 3rem 0;
  font-weight: bold;
}
