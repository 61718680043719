@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.product-container {
    width: 95%;
    display: grid;
    grid-gap: .5rem;
    margin: 25px auto;
    max-width: $max-width;

    // Estilos para la caja que rodea los Card
    div {
      background-color: transparent;
      outline: 0;
    }

    // Estilos para la grilla
    grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    @include from(426px) {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
    @include from(720px) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    justify-content: center;
    @include from(m) {
      width: 95%;
      grid-gap: 1rem;
    }
    @include from(880px) {
      width: 95%;
      grid-gap: 1rem;
    }
}
.pagination-button {
    display: block;
    margin: 0 auto;
}

.main-empty-home {
  width: 95%;
  margin: 2rem auto;
  max-width: $max-width;
  padding: 3rem 1.5rem;
}
