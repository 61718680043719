@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.banner-cart {
  width: 100%;
  height: 130px;
  background: linear-gradient(to right,rgba(39,125,193,1) 20%, rgba(159,197,31,05) 110%);
  position: relative;
  margin-bottom: 2.5rem;
  @include from(m) {
    margin-bottom: 3rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-cart-content {
  width: 100%;
  background-color: transparent;
  max-width: $max-width;
  margin: 0 auto;
}
  
.banner-cart--title {
  text-align: center;
  color: white;
  padding: 0;
  margin: 0 auto;
}
  
// .banner-cart--logoContainer {
//   position: absolute;
//   left: 50%;
//   top: 95%;
//   transform: translate(-50%,-50%) ;
// }
  
// .banner-cart--logo {
//   width: 130px;
//   height:100%;
// }
