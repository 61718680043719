@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.order-detail-container {
  margin-top: 1.5rem;
  padding: 1rem;
}

.order-detail-item {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 21px;
  padding: 1.5rem;
  @include from(470px) {
    display: flex;
    align-items: center;
  }
}

.order-detail-img {
  width: 100%;
  height: 100%;
  max-height: 250px;
  @include from(470px) {
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    display: flex;
  }
}

.order-detail-content-container {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0,0,0,.1) ;
  margin-top: 1.5rem;
  padding: .5rem;
  p {
    margin: .5rem;
  }
  @include from(470px) {
    border-top: 0;
    margin-top: 0;
    margin-left: 1.5rem;
    border-left: 1px solid rgba(0,0,0,.1) ;
    max-height: 200px;
  }
}

.order-detail-store, .order-detail-client {
  span {
    color: $secondColor;
    font-style: italic;
  }
  @include from(470px) {
    font-size: 1.1rem;
  }
}

.order-detail-store {
  margin-bottom: 0;
}

.order-detail-client {
  margin-bottom: .5rem;
}
.order-detail-description {
  display: block;
  text-overflow: ellipsis;
  word-spacing: nowrap;
  overflow: hidden;
  max-height: 50px; //temploral
}
.order-detail-withdrawal {
  max-height: 50px; //temploral
}

.order-detail-time {
  font-size: 1.1rem;
}
