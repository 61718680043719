@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.ant-drawer-body {
  padding: 0 0.5rem 0 0 !important;
}
.container-drawel_header {
  // background-color: red;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

button:focus {
  outline: 0 !important;
}
.container-drawel_imageContainer {
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.container-drawel_description {
  max-width: 140px;
  margin-left: 15px;
  line-height: 15px;
  overflow: hidden;
  p {
    white-space:nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6, p {
    margin-bottom: 0;
  }
}


// Menu custom estilos 

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0;
}

.btn-drawer {
  svg {
    position: relative;
    top: -1px;
    width: 15px !important;
    height: 15px !important;
  }
}
