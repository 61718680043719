.container-not-schedules {
  margin-top: 1.5rem;
  text-align: center;
  padding:  2rem 1rem;
}


.specialScheduleCheck  {
  width: 100%;
  // background-color: red;
  display: block;
}


.special-schedule {
  width: 100%;
  // background-color: red;
  display: block;
}
