@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

// .profile-container {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   @include from(m) {
//     margin-left: 256px; // Del tamaño del menu vertical
//   }
// }
.form-item-category {
  margin-bottom: 25px;
  @include from(576px) {
    display: flex;
  }
  label {
    color: $textColor;
    margin-right: 8px;
  }
}
.profile-minimumDelivery {
  margin-top: 0;
}

.btn-editStore {
  margin-top: 20px;
}

.form-editStore {
  margin-top: 20px;
}

.form-editar-foto {
  width: 95%;
  margin: 20px auto 30px auto;
  padding: 2rem 1.5rem;
  background-color: white;
}

.title-form-information {
  font-size: 1.2rem;
  @include from(m) {
    font-size: 1.8rem;
  }
  font-weight: normal !important;
  padding: 1rem;
  margin-top: 1.5rem;
}
