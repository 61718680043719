@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.error_popup {
  width: 100%;
  color: white;
  text-align: center;
  display: block;
  padding: 0 2rem;
  line-height: 1rem;
  font-size: var(--body-font-size);
}
.error_popup-blue {
  width: 100%;
  color: $secondColor;
  text-align: center;
  display: block;
  padding: 0 2rem;
  line-height: 1rem;
  font-size: var(--body-font-size);
}

.item-spiner-google {
  color: white !important;
  .ant-spin {
    color: white !important;
    .ant-spin-dot, .ant-spin-dot-spin {
      color: white !important;
      .ant-spin-dot-item {
        color: white !important;
        background :white !important;
      }
    }
  }
  div, span, p {
    color: white !important;
  }
}
.item-spiner-google-blue {
  color: $secondColor !important;
  .ant-spin {
    color:  $secondColor !important;
    .ant-spin-dot, .ant-spin-dot-spin {
      color:  $secondColor !important;
      .ant-spin-dot-item {
        color:  $secondColor !important;
        background : $secondColor !important;
      }
    }
  }
  div, span, p {
    color: $secondColor !important;
  }
}
