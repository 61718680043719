@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.edit-photos-store-container {
  margin-top: 1.5rem;
  padding: 1rem;
    // background-color: red;
}
.edit-photos-store {
  // padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(240px,1fr));
  grid-template-rows: auto;
  grid-gap: 1rem;
}

.form-editar-foto-store {
  width: 100%;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: $border-radius-box;
  padding: 1rem;
  // flex: auto;
}
