@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$change-1: 550px;
$change-2: 830px;
$change-3: 870px;

.main-card-product-item {
  // background-color: green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: $box-shadow-box;
  }
}

.main-card-product-item-container-img {
  width: 30%;
  width: 100px;
  height: 100px;
  border: 1px solid #f5f5f5;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}


.main-card-product-item-container-data {
  // background-color: orange;
}

.product-item-container-data {
  padding-left: .5rem;
  font-size: var(--body-font-size);
}

.product-item-container-data-title, .product-item-container-data-status-available, .product-item-container-data-status-not-available, .product-item-container-data-price {
  margin-bottom: 0 !important;
  line-height: 18px;
  span {
     margin-bottom: 0;
  }
}

.container-img-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // background-color: red;
}

.main-card-product-item-container-btns {
  max-width: 100%;
  width: 100%;
  // background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
  padding-top: .5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @include from($change-1) {
    width:auto;
    justify-content: space-between;
    border-top: none;
    margin-top: 0;
    padding-top: 0;
    max-width: 250px;
    button {
      margin-left: .5rem;
    }
  }
  @include from($change-2) {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: .5rem;
    padding-top: .5rem;
    max-width: 100%;
  }
  @include from($change-3) {
    width: 40%;
    border-top: none;
    margin-top: 0;
    padding-top: 0;
    max-width: 200px;
    justify-content: space-around;
  }
 
  // background-color: orange;
}


.text-data {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  -o-text-overflow: ellipsis;
  max-width: 130px;
  padding: 0;
  line-height: 25px;

  @include from(375px) {
    max-width: 150px;
  }
  @include from(660px) {
    max-width: 210px;
  }
  @include from(690px) {
    max-width: 250px;
  }
  @include from(720px) {
    max-width: 290px;
  }
  @include from($change-3) {
    max-width: 150px;
  }
  @include from(1024px) {
    max-width: 210px;
  }
  @include from(1100px) {
    max-width: 260px;
  }
}
