@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-factura {
  width: 90%;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  // box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  background-color: white;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;

  @include from(m){
    width: 80%;
    padding: 1.5rem 2rem 0;
  }
  @include from(880px){
    width: 70%;
    padding: 1.5rem 3rem 0;
  }
}


.main-factura-data-seller {
  width: 90%;
  @include from(m){
    width: 80%;
  }
  @include from(880px){
    width: 70%;
  }
  padding: 1rem 0;
  margin: 0 auto;
  // background-color: blue;
  .main-factura-data-seller-title {
    text-align: left;
    margin: 0;
  }
}
.main-factura-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .main-factura-header-title {
//   margin-left: 2rem;
// }

.main-factura-item {
  border-top: 1px solid rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  p {
    margin-bottom: 0;
  }
}
.main-factura-quantity span, .main-factura-subtotal, .main-factura-total p {
  font-weight: 600;
}

.main-factura-subtotal {
  margin-right: 2rem;
}

.main-factura-quantity {
  min-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  @include from(480px){
    min-width: 250px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-factura-quantity span {
  margin-left: 2rem;
}

.main-factura-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0,0,0,.1);
  padding-top: 1rem;
  p {
    margin-right: 2rem;
  }
  // h5 {
  //   margin-left: 2rem;
  // }
}

.main-condiciones {
  border-top: 1px solid rgba(0,0,0,.1);
  // padding: 1rem;
  padding-top: 1rem;
  // text-align: center;
  
}

.main-condiciones-box-first, .main-condiciones-box-second {
  background-color:#f5f5f5;
  display: flex;
  align-items: center;
  text-align: left;
  // flex-wrap: wrap;
  padding: .8rem;
  border-radius: $border-radius-box;
}

.main-condiciones-box-second-form {
  background-color:#f5f5f5;
  display: flex;
  align-items: center;
  text-align: left;
  // flex-wrap: wrap;
  padding: .8rem;
  border-radius: $border-radius-box;
}

// estilos del formulario de direccion 
.user-address-form {
  background-color:#f5f5f5;
  padding: 1rem;
  @include from(m) {
    padding: 2rem;
  }
  @include from(880px) {
    padding: 0 1.5rem .2rem 3.8rem;
  }
  border-top: 2px solid #f5f5f5;
  margin-top: -4px;
  border-bottom-right-radius: $border-radius-box;
  border-bottom-left-radius: $border-radius-box;
}

.user-address-form-title {
  border-top: 2px solid rgba(0,0,0,.1);
  margin-bottom: 0;
  padding: 1rem 0;
}
.main-condiciones-box-first-data {
  padding-left: 1rem;
  line-height: 15px;
}
.main-condiciones-box-first-data-title{
  font-size:.72rem;
  @include from(375px) {
    font-size: var(--body-font-size);
  }
  margin-bottom: 0;
  font-weight: 700 !important;
  &.WhatsApp {
    color: $secondColor;
  }
}

.main-condiciones-box-first-data-terms{
  font-size: var(--small-font-size);
  margin-bottom: 0;
  padding-top: .2rem;
}

.main-condiciones-box-first-Checkbox, .main-condiciones-box-first-icon {
  padding: .4rem .7rem;
  background-color: white;
  border-radius: $border-radius-box;
}

.main-condiciones-box-first-data-terms-link {
  font-size: var(--small-font-size);
  text-decoration: underline;
}


.container-form-item {
  // background: salmon;
   .ant-row  {
    flex-wrap: wrap !important ;
  }
}



.container-form-item--input {
  width: 100% !important;
  display: block !important;
  margin: 0;
}
// Segunda caja
// .main-condiciones-box-second {
//   // background-color: green;
// }

.main-condiciones-RadioGroup {
  padding-top: .3rem;
  span {
    font-size: .75rem;
    @include from(375px) {
      font-size: var(--body-font-size);
    }
  }
}
.main-condiciones-form-number {
  // background-color: palegreen;
  padding: 1.3rem 1rem 0;
  border: 2px solid #f5f5f5;
  border-radius: $border-radius-box;
}

.main-condiciones-form-number-icon {
  // background-color: purple;
  display: flex;
  align-items: center;
  svg {
    color: #25D366;
    font-size: 1.5rem;
    position: relative;
    left: .3rem;
  }
  margin-bottom: .5rem;
}

.main-condiciones-form-number-container {
  padding-left: 2.8rem;
}
.main-condiciones-form-number-data {
  padding-left: 1.7rem;
}


.container-question-direction {
  background-color: #f5f5f5;
  padding: 1rem;
  @include from(880px) {
    padding: .3rem 1rem 1rem 4.2rem;
  }
  margin-top: -5px;
  border-bottom-right-radius: $border-radius-box;
  border-bottom-left-radius: $border-radius-box;
  p, button {
    font-size: var(--small-font-size);
  }
  button {
    margin-bottom: .5rem;
    @include from(510px) {
      margin-right: .5rem;
    }
  }
  .container-question-direction-data {
    color: $secondColor;
    font-weight: 700;
  }
}
.main-delevery {
  margin-top: 1rem;
}
.main-buttons-order {
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.1);
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 1rem .3rem 2rem;

  }
}

.input-delivery {
  margin: 1rem 0 .5rem;
}

.message-user {
  margin-top : 1rem;
}


.confirm-delivery-address-data {
  font-size: var(--body-font-size);
  padding: .5rem;
}
