/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.card-input-container {

  .success-box {
    width: 90%;
    max-width: 500px;
    padding: 1.5rem;
    margin: 0 auto;
  }
  .StripeElement {
      height: 40px;
      padding: 10px 12px;
      width: 100%;
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
      font-family: "Nunito", sans-serif
    }
    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }
    .card-input-container {
      display: flex;
      margin-bottom: 15px;
    }
    .card-label-color {
      color: rgba(0,0,0,0.85);
      width: 190px;
      position: relative;
      top: 4px;
    }
    .card-label-asterisk {
      color: red;
      position: relative;
      top: 3px;
    }
    .card-error-msg {
      border-radius: 21px;
      text-align: center;
      margin-top: 3px
    }
    
    .StripeElement--invalid {
      border-color: #fa755a;
    }
    
    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
    @media (max-width: 625px) {
      .card-input-container {
        flex-direction: column;
      }
      .card-label-color {
        top: -8px;
      }
    }
}