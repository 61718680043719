@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


$flex-cart-product: 450px;

// $max-width-parrafo: 220px;
// @include from(360px) {
//   $max-width-parrafo: 350px;
// }


// .card-products-container {
//   width: 100%;
//   // background-color: red; 
//   padding: 1rem;
//   @include from(800px) {
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
//     grid-gap: 1rem;
    
//   }
// }
.container-products-actives, .container-products-inactives {
  margin-top: 1.5rem;
}

.title-main-empty-product {
  padding-top: 1.5rem;
}
.products-container {
  // background-color: greenyellow;
  // max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  // @include from(800px) {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  //   grid-gap: 1rem;
    
  // }
  
  // @include from($flex-cart-product) {
  //   max-width: 100%;
  // }
}

// Botones de editar y eliminar
.btn-product {
  padding: 0 1.3rem;
}

.products-container-title {
  text-align: center;
  // margin-top: 2rem;
  margin-bottom: 1rem;
  color: $secondColor;
} 

// Cada producto
.card-product-item {
  // width: 100%;
  // background-color: red;
  margin-bottom: 1rem;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0 10px 0 rgba(0,0,0,.05) !important;
  border-radius: $border-radius-box;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2) !important;
    transition: all .3s !important;
  }
  @include from($flex-cart-product) {
    display: flex;
    align-items: center;
    // height: 150px;
  }
  @include from(880px) {
    margin-bottom:0;
  }
}
.product-img-carousel-container {
  position: relative;
  min-width: 150px;
  max-width: 150px;
  // border: 1px solid transparent;  
  padding: 1rem;
}
.product-img-carousel {
  width: 100%;
  height: 100%;
  // max-width:200px;
  max-height:200px;
  // height: 100%;
  // background-color: orange;
  @include from($flex-cart-product) {
    width: 30%;
    // height: 100%;
    min-width: 150px;
    max-width: 150px;
    max-height:150px;
    min-height: 150px;
  }
  position: relative;
  // width: 100%;
  // height: 100%;
  // background-color: green;
  // max-height: 300px;
  
}
.product-img {
  // width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
  min-height: 200px;
  max-height:200px;
  object-position: center;
  @include from($flex-cart-product) {
    // width: 100%;
    // height: 100%;
    // object-fit: fill;
    min-height: 150px;
    max-height:150px;
  } 
}
.img-carousel-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 3rem;
  color: lightgray;
  z-index: $z-normal;
  cursor: pointer;
}

.img-carousel-right {
  position: absolute;
  font-size: 3rem;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  color: lightgray;
  z-index:  $z-normal;
  cursor: pointer;
}

.card-product-content {
  // width: 100%;
  // height: 100%;
  // background-color: red;
  display:flex; 
  padding: 0 1rem 1rem 1rem;
  // text-align: center;
  justify-content: flex-start;
  // margin-left: 1rem;
  align-items: center;
  flex-wrap: wrap;
  @include from(m){ 
    // max-width: 210px;
    // $max-width-parrafo: 330px;
  }
  @include from($flex-cart-product){ 
    padding: 1rem 1rem 1rem .5rem;
  }
  p {
    margin-bottom: 0;
    // padding: 0.5rem 0;
  }
}

.card-product-title {
  font-size: 1.2rem;
  color: $secondColor;
  font-weight: 600;
}


.card-product-content-child {
  background-color: white;
}
.container-btn-product {
  background-color: green;
  width: 100%;
  display:flex; 
  justify-content: space-between;
  button {
    margin-top:.5rem;
  }
  @include from($flex-cart-product) {
    justify-content: flex-start;
    button {
      margin-right:.5rem;
    }
  }
  @include from(1224px) {
    justify-content: space-between;
  }
  align-items: center;
  // padding: 0 .8rem .8rem;
}
.margin-bottom-products {
  margin-bottom: 1.5rem;
}

.product-status-available {
  padding: 0;
  span {
    font-weight: 600;
    color: #52C41A;
  }
}
.product-status-notavailable {
  p {
    padding: 0;
  } 
  span {
    font-weight: 600;
    color: #FF4D4F;
  }
}

.container-data-description-empty {
  font-style: italic;
  font-size: .9rem;
}
// .card-product-content {
//   width: calc(100%);
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// Ocultando cuando el texto se sale del flujo
.container-data {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  -o-text-overflow: ellipsis;
  max-width: 230px;
  padding: 0;
  line-height: 25px;

  @include from(375px) {
    max-width: 290px;
  }
  @include from($flex-cart-product) {
    max-width: 170px;
  }
  @include from(530px) {
    max-width: 240px;
  }
  @include from(630px) {
    max-width: 320px;
  }
  @include from(715px) {
    max-width: 400px;
  }
  @include from(780px) {
    max-width: 460px;
  }
  @include from(820px) {
    max-width: 490px;
  }
  @include from(830px) {
    max-width: 240px;
  }
  @include from(880px) {
    max-width: 300px;
  }
  @include from(920px) {
    max-width: 340px;
  }
  @include from(960px) {
    max-width: 370px;
  }
  @include from(990px) {
    max-width: 410px;
  }
  @include from(1024px) {
    max-width: 430px;
  }
  @include from(1091px) {
    max-width: 470px;
  }
  @include from(1108px) {
    max-width: 500px;
  }
  @include from(1150px) {
    max-width: 530px;
  }
  @include from(1200px) {
    max-width: 580px;
  }
  @include from(1224px) {
    max-width: 190px;
  }
}
