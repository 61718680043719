@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$changeDesktop : 830px;



// title del drawer
.ant-menu-submenu-title span{
  font-size: var(--body-font-size) !important;
}
// Submenu dentro de otro submenu
.submenu-item {
  padding-left: .7rem !important;
}
// estilos a los item hijos del ultimo submenu
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  height: 38px;
  line-height: 38px;
  border-top: 1px solid rgba(0,0,0,0.1);
  font-size: var(--small-font-size) !important;
} 
.ant-menu-sub.ant-menu-inline > .ant-menu-item:last-child {
  border-bottom: 1px solid rgba(0,0,0,0.1);
} 
// Ajustando más a la izquierda  titulos de cada menu.
.ant-menu-submenu-title, .sub-rol-item {
  padding-left: 1rem !important;
}
// Ajustando más a la izquierda item(hijos) de cada menu.
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding-left: 2.6rem !important;
}

// Dandole estilos a los titulos del menu
.ant-menu-submenu-title, .sub-rol-item span {
  font-size: var(--body-font-size) !important;
  font-weight: 600 !important;
}
// Dandole estilos a los iconos del menu
.ant-menu-submenu-title, .ant-menu-item {
  svg {
    font-size: 1.1rem;
    position: relative !important;
    top: -2px !important;
  }
}

// .menu-item .anticon, .ant-menu-submenu-title .anticon {
//   display: flex;
//   align-items: center;
// }
.father-container-menu-vertical {
  position: fixed;
  background-color: white;
  top:0;
  // padding-top: $headerHeight;
  left: 0;
  z-index: 9; //Corregir Importante
  width: 280px;
  // height: calc($headerHeight - 100%);
  height: 100%;
  // min-height: 100vh;
  &:hover {
    overflow-y: auto;
  }
}
.container-menu-vertical {
  min-height: 100vh;
  max-height: 100vh ;
  overflow: hidden;
  &:hover {
    overflow-y: auto;
  }
}
.menu-vertical{
  padding-left: 0.5rem;
  // width: 256px;
  // overflow: auto;
  // min-height: 100vh;

    // display: block;
    padding-top: $headerHeight;
    
    // overflow-y: scroll; /* Add vertical scrollbar */
    // max-height: 100vh;
    // min-height: 100vh;
    // height: auto;
    width: 100%;
    // min-height: 100%;
    // justify-content: flex-end;
    background-color: white;
  
}
.father-container-menu-vertical {
  display: none;
  @include from($changeDesktop ) {
    display: block;
  }
}

// .active-menu {
//     display: block;
//     position: fixed;
//     top: 70px;
//     left: 0;
//     width: 256px; 
//     padding-top: 40px;
//     height: calc(100vh - 70px);
//     z-index: $z-tooltip;
//     background-color: white;
// }

// .toggle-collapsed {
//   // display:block;
//   // position: fixed;
//   // top: 70px; // Del tamaño del header 
//   // right: 0;
//   // z-index: $z-normal;
//   // background-color: $secondColor;
//   // display: none;
//   // @include from(830px) {
//   //   display: none;
//   // }
// }

.ant-drawer-body {
  padding: 0 .5rem !important;
}
