@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-footer {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: $z-modal;
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  @include from(768px) {
    justify-content: flex-end;
    bottom: 40px;
    left: -80px;
  }
}
.margin-before {
  height: 100px;
}
.main-footer--item {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: $iconColor;
  z-index: $z-modal;
  border-radius: 50%;
  margin: 0 7px;
  box-shadow: $box-shadow-box;
  border: 1px solid rgba(0,0,0,.15);
  @include from(480px) {
    margin:0 20px;
  }
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: $secondColor;
    color: white;
  }
  &:hover > .icon-title {
    display: block;
  }
  svg {
    font-size: 20px;
  }
}

.icon-title {
  display: none;
  background-color: $textColor;
  color: #eee;
  position: absolute;
  top: -50px;
  // min-width: 140px;
  height: auto;
  padding: .3rem var(--body-font-size);
  font-size: .65rem;
  white-space: nowrap;
  @include from(375px) {
    font-size: var(--body-font-size);
    padding: .3rem 1rem;
  }
  border-radius: $border-radius-box;
  &::before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: $box-shadow-box;
    border-top: 10px solid $textColor;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    }
}
