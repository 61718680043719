@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.separardor-ordes {
  line-height: 50px ;
  background-color: red;
}
.container-orders-reserved {
  margin-top: 1.5rem;
  padding: 1rem;
  @include from(m) {
    padding: 2rem;
  }
}

.container-orders-reserved-arrow {
  // margin-top: -10px;
  margin-left: 5px;
  padding-bottom: 10px ;
  svg {
    font-size: 1.2rem !important;
  }
}

.container-orders {
  position: relative;
}

.icon-load {
  position: fixed;
  cursor: pointer;
  top: 7.5rem;
  right: 20px;
  z-index: $z-normal;
  svg {
    color: $iconColor;
    font-size: 1.5rem;
  }
  &:hover {
    transform: scale(0.9);
  }
}

.quialifed-container {
  ul {
    margin-bottom: 1rem;
  }
}

.btn-quialifed-modal {
  display: inline-block;
  padding: .3rem 3.3rem ;
  height: 41px;
  text-align: center;
}

.cancel-button-qualified {
  color: red;
}

.view-datails-order {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.main-empty {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  h2 {
    color: $secondColor;
    text-align: center;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}


