@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.modal-icon {
    font-size: 40px;
    margin: 10px auto;
    display: block;
    color: lightgrey;
}
.modal-text {
    margin: 15px;
}
.center-btn {
    text-align: center;
}
.ant-modal-content {
    border-radius: 30px;
}
.container-map-btn {
  position: absolute;
  top: 75px;
  right: 0;
}
.map-container {
  width: 100%;
  height: calc(100% - 70px);
}
.view-store-button {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}
.map-buttons-container {
  position: fixed;
  bottom: 30px;
  width: 320px;
  display: flex;
  justify-content: space-around;
  left: 50%;
  transform: translateX(-50%);
}

#markerLayer img {
  border-radius: 50%;
}
.map-geolocate-button {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 40px;
}
.map-view-list-button {
  position: fixed;
  top: 85px;
  right: 60px;
}
.map-center-button {
  position: fixed;
  bottom: 100px;
  right: 10px;
}
.map-settings-button {
  position: fixed;
  bottom: 140px;
  right: 10px;
}
.map-main-footer--item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(80, 80, 80);
  z-index: $z-modal;
  cursor: pointer;
  background-color: white;
  &:hover {
    color: black;
  }
  &:hover > .icon-title {
    display: block;
  }
  svg {
    font-size: 20px;
  }
}
.loader-container2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.checkbox-block {
  margin-bottom: 0;
}
#map-form .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
}
#map-form .ant-checkbox-group-item {
  margin-top: 5px;
}
#map-form .ant-form-item {
  width: fit-content;
  margin: 0 auto;
}
.map-form-center-buttons {
  display: flex;
  justify-content: space-between;
  width: 175px;
  margin: 15px auto 0 auto;
}

#map-form .ant-checkbox-inner {
  transform: scale(1.28);

}
.map-form-center-buttons {
  width: 200px;
}
@media (max-width: 500px) {
   .map-view-list-button {
     left: 50%;
     transform: translateX(-50%);
     top: 80px;
   } 
}
.map-info-text {
	margin-top: 20px;
}


.btn-success {
  background-color: $firstColor !important;
  border: none !important;
  display: inline-block !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
  &:hover {
    background-color: $secondColor !important;
    border-color: transparent !important;
  }
  &:active {
    background-color: $secondColor !important;
    border-color: transparent !important;
  }
}

.btn-danger {
  background-color: transparent;
  color: $secondColor !important;
  font-size: var(--body-font-size) !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  text-decoration: underline !important;
  display: block !important;
  border: none !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 transparent !important;
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
  }
  &:active {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
  }
  &:visited {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
  }
  &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: 0 !important;
    box-shadow: transparent !important;
  }
}


