@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.btn-register {
  background-color: $secondColor;
  color: white;
}
.btn-back-store {
  // margin-top: 10px;
  background-color: #e7e4e4 !important;
  color: $textColor;
  &:hover {
    color: $textColor;
  }
}
.ant-form-item-control-input .ant-input-group-wrapper{
  padding: 0;
}
.ant-form-item-control-input .ant-input-group .ant-input-group-addon{
  border-radius: 16px 0 0 16px;
}
.ant-form-item-control-input .ant-input-group .ant-input{
  border-radius: 0 16px 16px 0;
}
.container-info-store {
  text-align: center;
  .info-store-title, .info-store-subtitle, .info-store-link {
    color: $secondColor;
  }
  .info-store-title {
    margin-bottom: 0;
  }
  .info-store-link {
    text-decoration: underline;
    font-size: var(--body-font-size);
  }
}
.ant-picker-now-btn {
  display: none;
}

.footer-info-store {
  padding: .5rem;
  font-size: var(--body-font-size);
  text-align: center;
  .footer-store-title {
    color: $secondColor;
    font-weight: 600;
  }
}
.inline-fields-form {
  justify-content: space-between;
}
.inline-field-form {
  width: 48%;
}
.ant-modal-content {
  max-width: 900px;
  margin: 0 auto;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
}
.antd-img-crop-modal .ant-btn {
  border-radius: $border-radius-box;
}
.antd-img-crop-modal .ant-btn-primary {
  background-color: $secondColor;
  color: white;
  border: none;
  outline: 0; 
}
.antd-img-crop-modal .ant-modal-content {
  border-radius: $border-radius-box !important;
  overflow: hidden;
}
.antd-img-crop-modal .ant-modal-footer {
  text-align: center;
}
.fix-width-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
