
@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


$responsive:820px;
$height-box: 350px;

.react-slick * {
  min-height: 0;
  min-width: 0;
}

.main-container-product-description {
  width: 95%;
  max-width: $max-width;
  margin: 1.5rem auto 0;
  // background-color: red;
}
.container-product-description {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 1rem;
  height: auto;
  @include from($responsive) {
    flex-direction: row;
    padding: 1rem 0;
    // align-items: flex-start;
  }
}

.container-img {
  width: 95%;
  max-height: 300px !important;
  // background-color: red;
  border-top: 1px solid rgb(223, 213, 213);
  padding-top: 1rem;
  margin: 5rem 20px 20px;
  // @include from(363px)  {
  //   margin: 5rem 20px 20px;
  // }
  @include from(680px)  {
    margin: 1.5rem 20px 20px;
  }
  @include from($responsive) {
    width: 60%;
    max-height: $height-box !important;
    border-right: 1px solid rgb(223, 213, 213);
    border-top:0;
    padding-right: 1rem;
    margin-right: 0rem;
    padding-top: 0;
  }
  // background-color: yellow;
  
  // flex: 0 0 65%;
  // max-height: $height-box !important;
  
}

.container-description {
  width: 100%;
  height: 100%;
  max-height: $height-box !important;
  // background-color: blue;
  @include from($responsive) {
    width: 40%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 0;
  }
}


.img-zoom-product {
  width: 100% !important;
  height: 100%  !important;
  max-width: 100% !important;
  // max-height: $height-box !important;
  max-height: 300px !important;
  @include from($responsive) {
    max-height: $height-box !important;
  }
  // max-height: $height-box !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: contain !important;
}

.container-zoom-pan {
  position: relative;
  // background-color: pink;
  height: 300px !important;
  max-height: 300px !important;
  @include from($responsive) {
    height: $height-box !important;
    max-height: $height-box !important;
  }
  // height: $height-box !important;
  // max-height: $height-box !important;
  div {
    height: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.tools-image-product {
  position: absolute;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10000 !important;
  width: 130px !important;
  height: 30px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: red;
  .tools-image-product-btn {
    width: 30px;
    height: 40px;
    text-align: center;
    // font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    padding: 0 ;
    background-color: $secondColor;
    cursor: pointer;
    transition: all .2s;
    border-radius: 50%;
    svg {
      color: white;
      font-weight: bold;
      font-size: 1.1rem;
      
      &:hover {
        transform: scale(1.1);
        transition: all .2s;
      }
    }
  }
}

.slick-prev, .slick-next {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}
.slick-prev:before, .slick-next:before {
  color: $secondColor !important;
}

.container-product {
  // background-color: red;
  max-width: $max-width;
  // width: 90%;
  margin: 2rem auto;
  .box-product {
    width: 95%;
    // border-radius: 20px;
    background-color: white;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
  }
}

.container-button-products-description {
  position: static;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  // background-color: red;
  button {
    margin: .5rem .4rem 0;
  }
  @include from($responsive) {
    justify-content: flex-start
  }
}

.store-product-delete {
  margin-top: 1.5rem;
}


// Informacion de la tienda
.product-info-store-description {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dfd5d5;
  display: flex;
  align-items: center;
  justify-content: center;
  @include from($responsive) {
    justify-content: flex-start;
  }
}

.product-info-store-img-container {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  background: linear-gradient(140deg, $secondColor 20%, $firstColor 110%);
  img {
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    padding: 3px;
  }
}

.product-info-store-data-container {
  text-align: left;
  padding-top: 1rem;
  // background-color: steelblue;
  padding-left: 10px;
  line-height: 20px;

  .product-info-store-data-container-storeName {
    color: $secondColor;
    margin-bottom: 0;
    font-weight: 600;
  }
  
  .product-info-store-data-container-category {
    margin-bottom: 0;
    font-size: var(--body-font-size);
    line-height: 15px;
  }
  .ant-rate {
    .ant-rate-star  {
      margin-right: 4px !important;
    }
    svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
}


// 
.product-info-container {
  position: relative;
  // background-color: red;
  height: 100%;
  padding: .7rem;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  @include from($responsive) {
   padding: 1rem;
  }
}

.product-info-productName { 
  color: $secondColor;
  margin-top: 1rem;
  margin-bottom: 0;
  max-height: 55px;
  overflow: hidden;
  font-size: 1.6rem;
  @include from($responsive) {
    text-align: left;
  }
}

.product-info-description-data {
  margin-bottom: 0;
  font-size: var(--body-font-size);
  margin: 5px 0 10px;
  span {
    font-weight: 600;
    color: $textColor;
  }
  @include from($responsive) {
    text-align: left;
  }
}

.container-product-info-product-price {
  display: flex;
  justify-content: center;
  @include from($responsive) {
    justify-content: flex-start;
    padding-left: .5rem;
  }
}
.product-info-product-price {
  font-weight: 600;
  color: $textColor;
  font-size: var(--body-font-size);
  width: auto;
  display: inline-block;
  background-color: #f5f5f5;
  border-radius: $border-radius-box;
  padding: 10px 20px;
  margin: 15px 0;
  svg {
    color: rgb(87, 214, 87);
    font-size: 1rem;
    position: relative;
    top: -1px;
  }
  @include from($responsive) {
    margin: 5px -5px;
  }

}

.product-info-quantity-container {
  width: 100%;
  // background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  @include from($responsive) {
    justify-content: flex-start;
  }
}

.product-info-quantity-container-text {
  font-size: var(--body-font-size);
  margin-bottom: 0;
  padding-right: 5px;
  text-align: center;
}

.product-quantity-container {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: .3rem 0;
    // background-color: orange;
    align-items: center;
}

.product-quantity-action {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: $secondColor;
  // font-weight: 600;
  background-color:white;
}

.action-one,  .action-two {
  border: 1px solid rgb(223, 213, 213);
}

.action-one {
  border-top-left-radius: $border-radius-box;
  border-bottom-left-radius: $border-radius-box;
  border-right: 0;
}
.action-two {
  border-top-right-radius: $border-radius-box;
  border-bottom-right-radius: $border-radius-box;
  border-left: 0;
}

.product-quantity-action:hover {
  cursor: pointer;
  background-color: $secondColor;
  color: white;
}

.product-quantity-input {
  border-radius: 0;
  height: 25px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  color: $secondColor;
  font-weight: 700;
}

.producto-agotado {
    text-align: center;
    color: red;
    font-weight: 500;
}

.product-info-btn {
  
  @include from($responsive) {
    float: left;
  }
  &.in-to-cart {
    margin-top: 5px;
  }
}
.icon-scroll {
  position: fixed;
  transition: opacity .3s;
  opacity: 1;
  bottom: 75px;
  right: 33px;
  cursor: pointer;
  z-index: $z-fixed;
  svg {
    color: $secondColor;
    font-size: 1.9rem;
    cursor: pointer;
  }
  @include from($responsive) {
    display: none;
  }
}
