
@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.new-product-form {
  margin-left: auto; 
  margin-right: auto; 
  padding: .1rem 1rem 1rem !important;
}
.container-product {
  width: 100%;
  max-width: 100%;
  
}
.ant-input-number-handler-wrap {
    display: none;
}
.ant-input-number-input {
    padding-left: 0px;
}
.showItem {
    display: block;
}
.hideItem {
    display: none;
}
// .btn-createProduct {
//     text-align: center;
//     margin-left: auto;
//     margin-right: auto;
//     display: block;
// 	margin-bottom: 10px;
// }

.new-product-form {
  margin-top: 1.5rem;
  padding: 2rem;
}


.box-product-name-form, .box-product-description-form, .box-product-storeName-form {
  display:block;
  margin-bottom: .2rem;
}
.img-carousel-container {
  width: 100%;
  max-height: 300px !important;
}


.product-info-product-price-prev {
  font-weight: 600;
  color: $textColor;
  font-size: var(--body-font-size);
  width: 230px;
  background-color: #f5f5f5;
  border-radius: $border-radius-box;
  padding: 10px 20px;
  margin: 15px auto;
  svg {
    color: rgb(87, 214, 87);
    font-size: 1rem;
    position: relative;
    top: -2px;
  }
}

.img-carousel-form {
  width: 100%;
  height: 100%;
   max-height: 300px !important;
   min-height: 300px !important;
   display: flex;
   align-items: center;
}

.img-carousel-form-2 {
  height: 100%;
  width: 100%;
  min-height: 300px !important;
}

.carousel-product-img-form {
  width: 100% !important;
  height: 100%  !important;
  max-width: 100% !important;
  // max-height: 500px !important;
  max-height: 300px !important;
  min-height: 300px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  object-fit: contain !important;
}
