@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.order-details-container {
  margin-top: 1.5rem;
  padding: 2rem 1rem;
}
.order-details-header {
  // background-color: green;
  h2 {
    padding: .5rem 1rem 0;
    color: $textColor;
    font-size: 1.35rem !important;
    margin-bottom: 0;
  }
}

.order-details-summary {
  // border: 1px solid rgba(0,0,0,.1);
  // border-radius: 21px;
  padding: 1rem 1rem 0;
  font-size: var(--body-font-size);
 
}

.order-details-summary-header {
  p {
    margin: 0;
  }
  h2 {
    font-size: 1.35rem;
  }
  .order-details-summary-status {
    margin-top: var(--small-font-size);
    line-height: 18px;
    // font-size: 1.25rem;
  }
}

.order-details-summary-price {
  color: $textColor;
  span {
    color: $secondColor;
  }
}
.order-details-summary-body {
  width: 100%;
  margin: 0 auto;
  // background-color: red;
  @include from(440px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.order-details-summary-footer {
  padding: .5rem;
  text-align: center;
  @include from(440px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-details-summary-storeName {
    margin: 0;
    span {
      color: $secondColor;
    }
  }
}

.order-details-summary-content {
  font-size: var(--body-font-size);
  padding: 1rem 0;
  p {
    font-size: var(--body-font-size);
    margin: 0;
  }
  .order-details-summary-name, .order-details-summary-description {
    font-weight: 500;
    color: $secondColor;
    line-height: 20px;
    font-size: var(--body-font-size);
  }
  .order-details-summary-name {
    color: $firstColor;
  }
  .order-details-summary-description {
    color: $secondColor;
  }
}

.order-details-summary-img {
  // width: 100%;
  // height: 100%;
  // max-width: 350px;
  // max-height: 250px;
  // @include from(440px) {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
  // }
}

.order-details-summary-order-item {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  // background-color: orange;
  // border-radius: 21px;
  &:last-child {
    border-bottom: 0;
  }
}

.order-details-summary-order-item-noti {
  width: 100%;
  // background-color: green;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.order-details-summary-name, .order-details-summary-description {
  max-width: 220px;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  line-height: 25px;

  @include from(360px) {
    max-width: 260px;
  }
  @include from(390px) {
    max-width: 290px;
  }
  @include from(440px) {
    max-width: 260px;
  }
  @include from(470px) {
    max-width: 290px;
  }
  @include from(530px) {
    max-width: 340px;
  }
  @include from(580px) {
    max-width: 390px;
  }
  @include from(630px) {
    max-width: 440px;
  }
  @include from(680px) {
    max-width: 480px;
  }
  @include from(715px) {
    max-width: 515px;
  }
  @include from(750px) {
    max-width: 545px;
  }
  @include from(820px) {
    max-width: 610px;
  }
  @include from(830px) {
    max-width: 330px;
  }
  @include from(880px) {
    max-width: 380px;
  }
  @include from(920px) {
    max-width: 430px;
  }
  @include from(960px) {
    max-width: 460px;
  }
  @include from(990px) {
    max-width: 490px;
  }
  @include from(1024px) {
    max-width: 520px;
  }
  @include from(1050px) {
    max-width: 550px;
  }
  @include from(1100px) {
    max-width:590px;
  }
  @include from(1170px) {
    max-width:660px;
  }
  @include from(1200px) {
    max-width:675px;
  }
}
