@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.form-edit-login-container {
  margin-top: 1.5rem;
}

.form-edit-login {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  border-radius: 20px;
  background-color: white;
}
