@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.ant-card-body {
  // background-color: blue;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.05) !important;
  border: transparent !important;
  outline: 0px !important;
  border-radius: 8px !important;
  background-color: white !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  transition: all .3s;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2) !important;
    transition: all .3s;
  }

  // estrellas del box 
  .ant-rate {
    font-size: 11px !important;
    @include from(375px) {
      font-size: 15px !important;
    }
  }

.ant-card-bordered {
  border: 0 !important;
} 

  .ant-rate .ant-rate-disabled {
    // background-color: red !important;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: center !important;
    
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 3px !important;
    @include from(371px) {
      // margin-right: 8px !important;
    }
  } 
}

.container-rate {
  width: 100%;
  // background-color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 11px !important;
    height: 11px !important;
  }
}

.card-seller-name {
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-size: var(--small-font-size);
  margin-bottom: 0;
  @include from(m) {
    font-size: var(--body-font-size);
  }
  // @include from(880px) {
  //   font-size:1.2rem;
  // }
}

.container-seller-description {
  // min-height: 110px;
  font-size: .8rem;
  @include from(m) {
    font-size:var(--small-font-size);
  }
  padding: .7rem !important;
  // @include from(880px) {
  //   font-size:1rem;
  // }
  p {
    padding-top: 5px;
  }
}
.blue-text {
    color: $secondColor;
}
.gray-text {
  color: gray;
}
.main-text {
    color: $textColor;
}
.seller-btn {
    color: $firstColor;
    border: 1px solid $firstColor;
    margin-top: 20px;
}
.seller-box-location {
  margin-bottom: 0;
  text-align: center;
  font-size: .7rem;
  @include from(m) {
    font-size:var(--small-font-size);
  }
}
.seller-img {
  width: 100%;
  height: 100%;
  // min-width: 100%;
  // max-width: 120px;
  min-height: 85px;
  max-height: 85px;
  // max-height: 85px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  // border-radius: 21px;
  // margin-bottom: 10px;
  margin-right: auto;
  object-fit: contain !important;
  object-position: center;
  margin-left: auto;
  text-align: center;
  border-bottom: 1px solid rgba(0,0,0,.05);
  // @include from(371px) {
  //   width: 100%;
  //   // max-width: 250px;
  //   min-height: 150px;
  //   max-height: 150px;
  // }
  @include from(426px) {
    width: 100%;
    // max-width: 150px;
    min-height: 120px;
    max-height: 120px;
  }
  
  @include from(725px) {
    width: 100%;
    // max-width: 165px;
    min-height: 140px;
    max-height: 140px;
  }
}


.seller-box-category {
  margin-bottom: 0;
  padding: 0 !important;
  min-height: 30px;
  // background-color: red;
  text-align: center;
  font-size: .7rem;
  line-height: 14px;
  @include from(m) {
    font-size:var(--small-font-size);
  }
}

.btn-seller-box {
  display: none;
}




