@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-cta-pwa {
  margin: 3rem auto 0;
}

.btn-pwa {
  max-width: 370px;
  margin: 0 auto;
  &:hover {
    background-color: $secondColor;
  }
  &:active {
    transform: scale(0.9); 
  }
}
.install-pwa {
  width: 65px;
  height: 65px;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.img-logo-pwa {
  // max-width: 370px;
  width: 171px;
  height: 85px;
  margin: 1rem auto;
  cursor: pointer;
  svg {
    width: 100%;
  }
}
