// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;600;700&display=swap');

// Aqui van los estilos base o generales de la aplicación. 

// Sistema de columnas de 5% a 100%
@each $bp, $size in $breakPoints {
  @media screen and (min-width: $size) {
      @for $i from 1 through 20 {
          .#{$bp}-#{$i*5} {
            width: #{$i * 5%};
          }
      }
  }
}

// Sistema de margen de 5px a 100px
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  // font-family: 'Nunito', sans-serif;
  // font-family: 'Noto Sans JP', sans-serif;
  font-family: 'Nunito Sans', sans-serif;
}

body {
	padding: 0;
	line-height: 1.6;
  font-size: var(--body-font-size);
  background-color: #f5f5f5;
  // font-family: 'Nunito', sans-serif;
  font-family: 'Noto Sans JP', sans-serif;
  color: $textColor;
}

html, body, #root {
  height: 100%;
}


// Aqui va toda nuestra apliacion / este es el contenedor padre
.app {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  height: auto;
}

li {
	list-style: none;
}

a {
	text-decoration: none;
}

.title-form {
  color: $titleColor;
  font-size: var(--h3-font-size) !important;
}

h1 {
  margin-top: 0;
  font-weight: 700 !important;
}
h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 600 !important;
}

h1 {
  font-size: var(--h1-font-size);
}
h2 {
  font-size: var(--h2-font-size) !important;
  // font-weight: 300;
}

.center-text {
  text-align: center;
}

.background-white {
  background-color: white;
}

// Todos los botones deben detener 40px
button {
  min-height: 40px !important;
  padding-left: 1.3rem !important;
  padding-right:  1.3rem !important;
}



.ant-btn-dangerous {
  font-size: var(--small-font-size) !important;
  background-color: transparent !important;
  border: 1px solid #ff7875 !important;
  color: #ff7875 !important;
  &:hover {
    background-color: #ff7875 !important;
    color: white  !important;
  }
  &.background-red {
    background-color: #ff7875 !important;
    color: white  !important;
  }
}

// .ant-btn-dangerous {
//   background-color: #f5f5f5 !important;
//   &:hover {
//     background-color: #e7e4e4 !important;
//   }
// }


.btn {
  display: block;
  width: 100%;
  font-size: var(--small-font-size) !important;
  height: 40px;
  // border: 0;
  border-radius: $border-radius-button;
  transition: none !important;
  white-space: nowrap;
}

.btn-first {
  background-color: $secondColor;
  color: white;
  font-size: var(--small-font-size) !important;
  border: 1px solid $secondColor;
  outline: 0;
  border-radius: $border-radius-button;
  transition: none !important;
  white-space: nowrap;
}

.btn-second {
  transition: none !important;
  background-color: $firstColor;
  color: white;
  font-size: var(--small-font-size) !important;
  border: 1px solid $firstColor !important;
  outline: 0;
  border-radius: $border-radius-button;
  white-space: nowrap;
  &:hover {
    background-color: white !important;
    color: $firstColor !important;
    outline: 0;
    // border: 1px solid $firstColor;
  }
}



.gray-text {
  color: gray;
}

.buttons-form-register {
  border-radius: $border-radius-button;
  @include from(m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.form {
  width: 95%;
  margin: 20px auto 30px auto;
  box-shadow: $box-shadow-box !important;
  padding: 2rem 1.5rem;
  border-radius: $border-radius-box;
  background-color: white;
}

.box {
  box-shadow: $box-shadow-box !important;
  border-radius: $border-radius-box;
  background-color: white;
}

.box-card {
  box-shadow: $box-shadow-box !important;
  border: 1px solid rgba(0,0,0,.1) !important;
  border-radius: $border-radius-box !important;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.09) !important;
    transition: all .3s !important;
  }
}

.max-with-form {
  max-width: $max-with-form;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

label {
  font-weight: 600;
  font-size: var(--body-font-size) !important;
}
input, select, textarea {
  font-weight: 300;
  font-size: var(--body-font-size) !important;
  
}
.text-input {
  font-size: 1rem !important;
  padding: 6.5px 11px;
  border-radius: $border-radius-input;
  &::placeholder {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: var(--body-font-size) !important;
  }
}
.ant-input-password .ant-input {
  font-size: 1rem;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}

// INPUT NUMBER
.ant-form-item-control-input .ant-input-group .ant-input {
  border-radius: $border-radius-box !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
} 

.ant-form-item-control-input .ant-input-group .ant-input-group-addon {
  border-radius: $border-radius-box !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ant-modal-body, .ant-modal-content, .ant-modal{
  border-radius: $border-radius-box !important;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}
// ESTILOS PARA LOS INPUT SELECT
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  border-radius: $border-radius-input !important;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: $border-radius-input !important;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: $border-radius-input !important;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}
// ESTILOS PARA LOS ITEMS DE LOS INPUT SELECT
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border-radius: $border-radius-input !important;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}
// ESTILOS PARA LOS INPUT DE HORA(TIME)
.select-input.ant-picker, .ant-input-number {
  width: 100%;
  display: block;
  border-radius: $border-radius-input;
  font-weight: 300;
  font-size: var(--body-font-size) !important;
}

.center-block {
  display: block;
  margin: 0 auto;
}

.form-info {
  display: block;
  width: fit-content;
  margin: 0 auto;
  font-size: var(--body-font-size);
}

.font-size-body {
  font-size: var(--body-font-size);
} 
.error-msg {
  border-radius: $border-radius-button;
  text-align: center;
  margin-top: -12px;
  margin-bottom: 24px;
}

.round-btn {
  border: 0;
  border-radius: $border-radius-button;
}

.round-box {
  border-radius: $border-radius-box;
}

.round-input {
  border-radius: $border-radius-input;
}

.round-button {
  border-radius: $border-radius-button;
}

.btn-register {
  background-color: $secondColor;
  color: white;
}

.button-small {
  min-height: 30px !important;
  max-height: 30px !important;
}

.btn-block {
  display: block!important; 
  width: 100% !important;
}
.btn-small {
  width: 150px !important ;
}
.btn-medium {
  width: 190px !important ;
}
.btn-large {
  width: 500px !important ;
}

.sweetAlert__customConfirmButton {
  background-color: #EC3748;
  border-radius: $border-radius-button;
  border: none;
  cursor: pointer;
  padding: 5px 20px;
  margin-right: 10px;
  font-size: var(--body-font-size) !important;
  &:hover {
    background-color:  #EC3748;
    border: none;
  }
}

.sweetAlert__customCancelButton .swal2-cancel .swal2-styled {
  color: #95989A;
  background-color: transparent;
  border-radius: $border-radius-button;
  border: 1px solid #95989A !important;
  cursor: pointer;
  padding: 5px 20px;
  margin-right: 10px;
  font-size: var(--body-font-size) !important;
  &:hover {
    color: #95989A;
    background-color: transparent !important;
    border: 1px solid #95989A !important;
  }
}

#swal2-title.swal2-title {
  font-size: 1.2rem !important;
}

.swal2-popup {
  background: white !important;
  border-radius: $border-radius-box !important;
  .swal2-title {
    color: $secondColor !important;
    font-size: 1.3rem;
    font-weight: normal !important;
    @include from(m) {
      font-size: 2rem !important;
    }
  }
  .swal2-icon.swal2-warning {
    box-shadow: none;
    color:  #EC3748 !important;
    border-color:  #EC3748 !important;
    font-size: 1rem !important;
    margin: .8rem .5rem;
    font-weight: normal !important;
  }

  .swal2-styled.swal2-confirm {
    box-shadow: none;
    border: none !important;
    border-radius: $border-radius-box !important;
    background-color: $secondColor !important;
    color: #fff;
    font-size: .9rem;
    padding: .5rem 2rem !important; 
    font-weight: normal !important;
    @include from(m) {
      font-size: 1rem !important;
    }
  } 
  .swal2-styled.swal2-cancel {
    box-shadow: none;
    border: none !important;
    border-radius: $border-radius-box !important;
    outline: 0px !important;
    background-color: #aaa !important;
    color: #fff;
    padding: .5rem 2rem !important; 
    font-size: .9rem;
    font-weight: normal !important;
    @include from(m) {
      font-size: 1rem !important;
    }
  }

  .swal2-actions {
    margin-top: 5px !important;
    // @include from(m) {
    //   margin-top: 20px !important;
    // }
  }
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center !important;
}
.ant-dropdown-menu {
  width: fit-content !important;
  top: 0 !important;

}

.main-empty {
  h2 {
    font-size: 1.2rem;
    @include from(m) {
      font-size: 1.5rem;
    }
    @include from(860px) {
      font-size: 2rem;
    }
  }
  img {
    width: 150px;
    height: 150px;
    @include from(m) {
      width: 200px;
      height: 200px;
    }
    @include from(860px) {
      width: 280px;
      height: 280px;
    }
  }
}


// spinner
.ant-spin-dot-item {
  &:nth-child(even) {
    background-color: $secondColor;
  }
  background-color: $firstColor;
  font-weight: bold;
  opacity: 0.5;
}

// Estilo al mensaje donde sale la dirección en google map
.gm-style-iw-d {
  padding-right: 10px !important;
  @include from(575px) {
    padding-right: 20px !important;
  }
}
.gm-ui-hover-effect {
  max-width: 30px !important;
  right: 2px !important;
  padding: 0 !important;
}
