@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$changeDesktop : 830px;

.container_card {
  width:100%;
  height: auto;
  margin: 0 auto;
}

.btn-edit-profile {
  margin-top: 1rem;
  padding: 0 2rem;
}

.profile-description-data {
  padding: .5rem 0;
  // background-color: red;
}

.card-profile {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: center;
  background-color: white;
  word-wrap: break-word;
  border: none;
  text-align: center;
  margin-top: 1.5rem;
  &.user {
    margin-top: 3.9rem;
    @include from(860px) {
      margin-top: 1.5rem;
    }
  }
}

.card-body-profile, .card-footer, .card-header {
  background: transparent;
  padding: 1.33rem 1.66rem;
}
.card-body-profile {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  text-align: center;
  // background-color: red;
  border-radius: 21px;
  // margin: 0 1rem;
}
.position-relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}
.rounded {
  border-radius: .25rem;
}
.img-fluid, .img-thumbnail {
  width: 100%;
  min-width: 100%;
  height: auto;
  /*min-height: 200px;
  max-height: 200px;
  @include from(m) {
    min-height: 300px;
    max-height: 300px;
  }*/
}
img, svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
.style_headerText__3nthk {
  position: absolute;
  top: 2.66rem;
  left: 2.66rem;
  font-size: 2.13rem;
  line-height: 2.13rem;
}
.text-white {
  color: #fff;
}
.font-weight-bold {
  font-weight: 700;
}
// @include from(m) {
  .flex-sm-row {
    flex-direction: row;
  }
// }
.flex-column {
    flex-direction: column;
}
.img-container {
  width: 100%;
  position: relative;
  // background-color: green;
}
.main-container-photo-profile {
  // background-color: green;
  position: relative;
}

.camera-image-portrait {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: $z-normal;
  svg {
    font-size: 2rem;
    color: $iconColor;
    font-weight: bold;
    &:hover {
      color: $secondColor;
    }
  }
}

.camera-image-profile {
  position: absolute;
  // top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -15px;
  width: 30px;
  height: 30px;
  display:flex;
  justify-content:center;
  align-items: center;
 
  background-color: rgba(#fdfbfb,.8);
  // padding: .2rem .6rem;
  border-radius: 50%;
  svg {
    font-size: 1rem;
    color: $iconColor;
    font-weight: bold;
    z-index: $z-modal !important;
    &:hover {
      color: $secondColor;
    }
  }
}

.container_card {
  width: 100%;
  // background-color: pink;
}
.style_user__1N3XK {
  // text-align: center;
  margin-top: -5.14rem;
  position: relative;
  z-index: 1;
}
.text-center {
  text-align: center;
}
.pr-5, .px-5 {
  padding-right: 3rem;
}
.pl-4, .px-4 {
  padding-left: 1.5rem;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.name-user {
  margin-top: 1rem;
  font-size: 1.5rem !important;
}


.kit__utils__avatar--rounded {
  border-radius: 50% !important;
}
.kit__utils__avatar {
  // width: 2.66rem;
  // height: 2.66rem;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f2f4f8;
  text-align: center;
  vertical-align: center;
}
.border-white {
  border-color: #fff;
}
.border-5 {
  border-width: .33rem;
}
.d-inline-block {
  display: inline-block;
}
.border-white {
  border-color: #fff;
}
.border {
  border: 3px solid #dee2e6 !important;
}
.kit__utils__avatar img {
  width: 100%;
  height: auto;
}
img, svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
.font-size-14 {
  font-size: .93rem;
}
.font-weight-bold {
  font-weight: 700;
}
.text-gray-5 {
  color: #a1a1c2;
}
.font-size-14 {
  font-size: .93rem;
}
a {
  text-decoration: none;
  transition: color .2s ease-in-out;
}
.pt-3, .py-3, .mt-0 {
  padding-top: 1rem !important;
}
// .container-photo-profile {
//   background-color: rgba(green,.5) !important;
// }
.style_user__1N3XK .text-center .pl-4 .pr-5 .flex-shrink-0 {
  padding: 0 !important;
}
.mt-0 {
  padding-top: 0 !important;
}
.mb-0, .my-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
  @include from (576px) {
    margin-top: 1rem !important;
  }
}
strong span {
  display: block !important;
}

.flex-sm-row {
  @include from(576px) {
    flex-direction: column!important;
  }
}


.tabs-profile {
  // background-color: red;
  width: 100%;
  // text-align: center;
  // background-color: red;
  // margin-top: 1rem;
}

.text-delivery-profile{
  margin-bottom: 1rem;
}

.name-user {
  margin-bottom: 1rem;
}

.profile-acordeon.ant-collapse {
  border-radius: 20px !important;
  margin-top: 1rem;
}

.profile-acordeon.ant-collapse > .ant-collapse-item:last-child {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 20px !important;
}

.profile-acordeon.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  font-weight: 600;
  text-align: center;
  svg {
    color: $iconColor;
    font-weight: 600;
  }
}
.profile-edit-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
}

.style_user__1N3XK {
  margin-top: -2rem;
}

.kit__utils__avatar--size84 {
  width: 150px;
  height: 150px;
}


@media (max-width: 700px) {
  .style_user__1N3XK {
    margin-top: -1rem;
  }
  .kit__utils__avatar--size84 {
    width: 115px;
    height: 115px;
  }
}
@media (max-width: 500px) {
  .style_user__1N3XK {
    margin-top: -.8rem;
  }
  .kit__utils__avatar--size84 {
    width: 75px;
    height: 75px;
  }
}

.container-description-profile {
    // background-color: pink;
    text-align: left;
    @include from(690px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @include from(830px) {
      display: block
    }
    @include from(1040px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  
  }


.profile-description-data {
  svg {
    color: $secondColor;
    margin-right: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center ;
  }
}

.user-name-profile {
  padding-top: .7rem;
  &.user {
    text-align: center;
  }
  @include from(480px) {
    padding-top: 0;
  }
}
.main-container-profile-user {
  // background-color: seagreen;
  font-size: var(--small-font-size) !important;
  position: relative;
  @include from(860px) {
    padding-left: 12rem;
  }
  &.store {
    @include from(860px) {
      padding-left: 1rem;
    }
  }
}

.main-container-profile-user-data {
  // background-color: slateblue;
  margin-top: 4rem;
  &.user {
    margin-top: 1.5rem;
  }
  @include from($changeDesktop) {
    margin-top: 1rem;
  }
  &.store {
    margin-top: 0 !important;
  }
}

.main-container-profile-user-name {
  // background-color: orange;           
  @include from(480px) {
    display: flex;
  }
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid #f5f5f5;
  // @include from(860px) {
  //   display: block;
  // }
  @include from(1024px) {
    display: flex;
  }
  
}

.user-name-profile {
  font-size: 1.3rem;
  margin-bottom: 0;
  @include from(860px) {
    margin-bottom: 10px;
  }
  @include from(1024px) {
    margin-bottom: 0;
  }
  svg {
    color: $secondColor;
    position: relative;
    top: -1px;
    left: 5px;
  }
}

.main-container-profile-user-direction {
  
  // background-color: palegreen; 
  @include from(480px) {
    display: flex;
  }
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 1rem;
  // @include from(860px) {
  //   display: block;
  // }
  @include from(1024px) {
    display: flex;
  }
  &.store {
    border-bottom: 2px solid #f5f5f5;
  }
  &.store-name {
    @include from(860px) {
      padding-left: 11rem !important;
    }
  }
}


.container-store-info-photo-user {
  height: 90px !important;
}

.portrait-photo-user-ori {
  height: 80px !important;
  background: linear-gradient(140deg, $secondColor 20%, $firstColor 110%);
}

.picture-photo-user {
  width: 90px !important;
  height: 90px !important;
  top: 0 !important;
  @include from(860px) {
    width: 120px !important;
    height: 120px !important;
    top: 50% !important;
  }
  img {
    width: 90px !important;
    height: 90px !important;
    @include from(860px) {
      width: 120px !important;
      height: 120px !important;
    }
  }
}

.user-direccion {
  padding-right: 15px;
  padding-bottom: 5px;
}

.user-email {
  margin-left: -45px;
  font-size: var(--small-font-size);
  text-align: center;
  display: none;
}

.user-contact,.user-direccion {
  padding-bottom: .6rem;
  padding-left: 5px;
  line-height: 20px !important;
  font-size: var(--body-font-size) !important;
  font-weight: 700 !important;
 span {
  //  color: $secondColor;
   font-weight: 300;
 }
 svg {
   color: $secondColor;
   position: relative;
   top: -1px;
   left: -6px;
 }
}

.user-contact {
  margin-bottom: 0;
  @include from(860px) {
    margin-bottom: 10px;
  }
  @include from(1024px) {
    margin-bottom: 0;
  }
}
