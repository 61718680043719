.white-bg {
    background-color: white;
    padding: 20px;
    width: 85%;
    margin: 0 auto;
}
.allow-list-style li {
    list-style: initial !important;
}
.faq-li-margin {
	margin: 1rem 0;
}