@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$changeDesktop : 830px;

.wrapped-profile-container {
  margin-right: auto;
  margin-left: auto;
  max-width: $max-width;
  width: 95%;
  height: auto;
  @include from($changeDesktop) {
    margin-left: 310px;
    margin-right: auto;
    width: calc(97% - 310px);
  }
  @include from(1224px) {
    margin-left: 280px;
    margin-right: auto;
    width: calc(99% - 280px);
  }
}
.profile-container {
  width: 100%;
  min-height: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}
