@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.btn-register {
  background-color: $secondColor;
  color: white;
}
.register-email-alert {
  margin-top: -10px;
  margin-bottom: 5px;
}

// .google-margin {
//   margin-bottom: 25px;
// }
// .google-register-btn {
//   margin: 20px auto;
// }

