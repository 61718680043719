@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

// Drawer notificacion
.container-drawel-notification {
  a {
    color: $iconColor;
  }
}
.img-notification-container {
  width: 23%;
  max-width: 60px;
  // height: 70px;
  // background-color: red;
  margin-left: 5px;
}
.container-drawel-noti {
  height: auto;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.not-read {
  background-color: rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: rgba(0, 0, 0, 0.10);
  }
}

.body-container-drawel-noti {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-delete-notification {
  position: absolute;
  z-index: $z-modal;
  bottom: 5px;
  right: 7px;
  // background-color: red;
  cursor: pointer;
  svg {
    &:hover {
      color: $secondColor;
    }
  }
}
.img-notification {
  width: 60px;
  height: 60px;
}
.msg-notification-container {
  width: 77%;
  // background-color: blue;
}
.msg-notification {
  font-size: .9rem;
  margin: 0;
  line-height: 19px;
  padding-right: .5rem;
}
.msg-notification-time {
  font-size: .8rem;
  margin: 0;
  padding-top: .4rem;
  font-weight: 600;
  // font-style: italic;
}

.msg-notification-name {
  color: $firstColor;
  font-weight: 600;
}

.not-notifications {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
}

.btn-notification-more {
  text-align: center;
  color: $secondColor;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    color: rgba($secondColor,.8);
  }
}
