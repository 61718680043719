@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


.container-StripeConsumerForm {
  padding: 1rem 1.5rem 3rem;
}
.title-StripeConsumerForm, .subtitle-StripeConsumerForm {
  font-size: 1.1rem;
  @include from(m) {
    font-size: 1.2rem;
  }
}