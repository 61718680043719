.ant-form-item-control-input-content .ant-tree-select {
  padding: 0;
}
.ant-form-item-control-input-content .ant-input-number-input {
  padding: 0;
}
.ant-form-item-control-input-content .ant-input-number {
  padding: 4px 11px;
}

.container-picture {
  
  position: relative;
}
.icon-delete-picture {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
}
