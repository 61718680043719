@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

$change-width: 470px;
$change-btn: 700px;

.container-carrito {
  background-color: transparent;
  max-width: $max-width;
  padding: .3rem;
  @include from(m) {
    // padding: 2rem 1rem;
  }
  @include from(780px) {
    // padding: 2rem 2.5rem;
  }
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.main-empty-cart {
  padding: 2rem 0;
}
.container-editar {
  line-height: 15px;
}
.cart-container {
  width: 100%;
}

// En carrito
.card-product {
  width: 100%;
  // max-width: 350px;
  padding: 1rem;
  margin-top: 5px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: $border-radius-box;
  background-color: white;
  h6 {
    margin-top: .3rem;
  }
  @include from(375px) {
    padding: 1.5rem;
  }
  @include from($change-width) {
    max-width: $max-width;
  }
  // background-color: white;
}
// En centa activas

.card-product-buyer-container {
  margin-bottom: 2rem;
}
.card-product-buyer {
  width: 100%;
  // max-width: 350px;
  // padding: 1rem;
  margin-top: 5px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid rgba(0,0,0,.1);
  border-radius: 20px;
  // background-color: blue;
  @include from(375px) {
    // padding: 1.5rem;
  }
  @include from($change-width) {
    max-width: $max-width;
  }
  // background-color: white;
}

// en compras Activas
.card-product-reserved {
  width: 100%;
  // max-width: 350px;
  // padding: 1rem;
  margin-top: 5px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid rgba(0,0,0,.1);
  border-radius: 20px;
  // background-color: blue;
  @include from(375px) {
    // padding: 1.5rem;
  }
  @include from($change-width) {
    max-width: $max-width;
  }
  // background-color: white;
}

// .card-product-in-cart {
//   width: 95%;
//   margin-left: auto;
//   margin-right: auto;
// }

.cart-seller-info {
  h6 {
    font-size: var(--body-font-size);
    margin-bottom: 0;
  }
  .cart-seller-info--name, .cart-seller-info--location,.cart-seller-info--totalPrice{
    margin-bottom: 5px;
  }
}
.cart-seller-info--name {
  color: $secondColor;
  margin-bottom: 0;
  font-size: 1.35rem;
}
.cart-seller-info--status {
  color: $firstColor;
  font-size: var(--body-font-size);
  margin: 0;
  font-weight: normal;
  margin-bottom: 0;
}

.container-remember-to-rate {
  // background-color: red;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  svg {
    margin-left: 10px;
  }
}
.remember-to-rate {
  color: $secondColor;
  // cursor: not-allowed;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 5px;
  &:hover {
    color: rgba($secondColor,.9);
  }
  span {
    color: red;
  }
}

.title-container {
  // height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.cart-seller-info--location{
  margin-bottom: .5rem;
}

.cart-seller-product-container >div:not(:first-child) {
  border-top: 1px solid rgba(51, 24, 207, 0.1);
}


// Card de ordenes: activas, historial eliminadas
.cart-seller-product {
  // background-color: red;
  margin-bottom: .7rem;
  height: 100%;
  @include from(320px) {
    display: flex;
    align-items: center;
  }
}


.btn-cart-reserved, .btn-cart-buyer, .btn-cart-canceled {
  margin-left: auto;
  margin-right: auto;
}

.btn-delete-order {
  border-top: 2px solid #f5f5f5;
  width: 100%;
  margin-top: .7rem;
  text-align: left;
  font-size: .9rem;
  display: block;
  padding: .5rem;
}
.cta-delete-order {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
// CARD DE ORDENES EN CARRITO 
.cart-seller-product-cart {
  // background-color: red;
  border-bottom: 1px solid rgba(51, 24, 207, 0.1);
  @include from(470px) {
    display: flex;
    align-items: center;
  }
  margin-bottom: 1rem;
  height: 100%;
  // padding: 1rem;
}

.cart-seller-description-others {
  font-size: var(--small-font-size);
}
.cart-seller-description {
  width: 100%;
  height: 100%;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 1rem;
  @include from($change-btn) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  p {
    margin: 0;
    line-height: 20px;
    span {
      font-weight: 300;
      @include from(m) {
        margin-left: 15px;
      }
    }
  }

}
// para la orden en carrito
.cart-seller-description-Incart {
  width: 100%;
  height: 100%;
  // border-top-right-radius: 21px;
  // border-bottom-right-radius: 21px;
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  // background-color: blue;
  @include from($change-width) {
    margin-left: 1rem;
    
  }
  // @include from($change-btn) {
  //   // width: auto;
  //   // flex-wrap: nowrap;
  //   // justify-content: center;
  // }
  p {
    margin: 0;
    line-height: 20px;
    span {
      font-weight: 300;
      @include from(m) {
        margin-left: 15px;
      }
    }
  }

}
.cart-seller-description-title {
  color: $secondColor;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: normal;
  font-size: var(--body-font-size);
}

.cart-reserved-title {
  text-align: center;
}

.cart-seller-img {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  // width: 100%;
  // height: 100%;
  // // max-width: 200px;
  // max-height: 200px;
  // object-fit: contain;
  // object-position: center;
  // @include from(400px) {
  //   max-height: 250px;
  // }
  // @include from($change-width) {
  //   width: 30%;
  //   min-width: 120px;
  //   max-width: 120px;
  //   max-height: 120px;
  //   min-height: 120px;
  //   // border-top-left-radius: 21px;
  //   // border-bottom-left-radius: 21px;
  // }

}

.container-btn-cart{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  button {
    font-size: var(--small-font-size);
    margin: .5rem 0;
    @include from(375px) {
      font-size: var(--body-font-size);
    }
    @include from($change-width) {
      font-size: .9rem;
    }
  }
}

.cart-description {
  width: 100%;
  height: auto;
  // padding-left: 1rem;
  padding-top: 1rem;
  line-height: 20px;
  p {
    margin: .1rem 0 .4rem ;
  }
  @include from($change-width) {
    padding-left: 0;
    // padding-top: .8rem;
    // margin-top: 1rem;
    padding-top: 0;
  }
  @include from(620px) {
    // padding-left: 1rem;
   
  }
  // margin-top: 2rem;
  // background-color: red;
}

.proando {
  .subtotoal-inCart {
    // background-color: yellow;
    width: 100%;
  }
  min-width: 100%;
  // background-color: green;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include from(620px) {
    flex-wrap: nowrap;
  }
}
.cart-seller-btn-actions {
  // background-color: red;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 5px;
  // padding:  0 1rem 1rem;
  padding-bottom: 1rem;
  // padding-left: 1rem;
  // padding-right: 1rem;
  // border-bottom-right-radius: 21px;
  // margin-left: auto;
  // margin-right: auto;
  align-self: flex-end;
  @include from($change-width) {
    justify-content: flex-end;
  }
  @include from(660px) {
    // padding-left: 1rem;
    padding-right: 1rem;
  }
  // @include from($change-btn) {
    
  //   width: auto;
  //   // justify-content: flex-start;
  //   padding: 0 1rem;
  //   // .cart-description-quantity {
  //   //   width: auto;
  //   // }
  // }
  .btn-edit { 
    color: $secondColor;
    border-bottom: 2px solid $secondColor;
    
    cursor: pointer;
  }
  .btn-cancel {
    color: #EC3748;
    cursor: pointer;
    margin-left: .5rem;
    border-bottom: 2px solid #EC3748;
  }
}

.container-editar {
  width: 100%;
  // background-color: blue;
  display: flex;
  align-items: center;
  margin: 2px 0;
}
.container-cartempty {
  width: 100%;
  margin-top: 4rem;
  text-align: center;
}
.tabs-cart {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}
.tabs-cart .ant-tabs-nav{
  
  // background-color: red;
  padding: 0 1rem;
  // margin: 3rem 0 0 0;
}

.cart-seller-info-data {
  // font-weight: 600;
  font-size: var(--body-font-size);
  margin-bottom: 0;

  span {
    color: $firstColor;
    font-weight: 300;
    &::after {
      content: '€';
      color: $firstColor;
    }
  }
}
.cart-seller-info--totalPrice {
  // margin: 0;
  font-weight: normal;
  span {
    color: $firstColor;
    &::after {
      content: '€';
      color: $firstColor;
    }
  }
}

.delivery-data{
  font-size: var(--body-font-size);
  line-height: 18px !important;
  span {
    color: $secondColor;
    font-weight: 600;
  }
}

.cart-seller-info--withDrawelTime {
  font-weight: normal;
  span {
    color: $secondColor;
  }
}
.cart-seller-info--withDrawelTime-undefined {
  font-weight: normal;
  color: $secondColor;
}

.container-withDrawelTime {
  // background-color: red;
  margin-top: -10px;
  margin-bottom: .8rem;
  button {
    margin: 0;
  }
}

// .container-editQuiantity {
//   margin-left: 20px;
//   // background-color: red;
//   button {
//     padding-top: 0;
//     padding-bottom: 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     span {
//       svg {
//         color: $secondColor;
//       }
//     }
//   }
// }

.message-withDrawelTime {
  // margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

// 
// .container-orders-reserved, .tab-profile-container {
//   margin-top: 1rem;
//   @include from($change-width) {
//     margin: 1rem;
//   }
//   @include from(720px) {
//     margin: 2rem;
//   }
//   // padding: 1rem;
// }

.btn-cart-empty {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.cart-seller-description-others p, .cart-seller-description-others h4 {
  max-width: 180px;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  line-height: 20px;
  // Media querys para cortas las palabras cuando son muy largas
  @include from(360px) {
    max-width: 220px;
  }
  @include from(440px) {
    max-width: 300px;
  }
  @include from(470px) {
    max-width: 140px;
  }
  @include from(530px) {
    max-width: 200px;
  }
  @include from(580px) {
    max-width: 250px;
  }
  @include from(630px) {
    max-width: 320px;
  }
  @include from(680px) {
    max-width: 350px;
  }
  @include from(715px) {
    max-width: 380px;
  }
  @include from(820px) {
    max-width: 440px;
  }
  @include from(830px) {
    max-width: 200px;
  }
  @include from(880px) {
    max-width: 250px;
  }
  @include from(920px) {
    max-width: 290px;
  }
  @include from(960px) {
    max-width: 330px;
  }
  @include from(990px) {
    max-width: 360px;
  }
  @include from(1024px) {
    max-width: 390px;
  }
  @include from(1050px) {
    max-width: 415px;
  }
  @include from(1100px) {
    max-width: 465px;
  }
  
  @include from(m) {
    // max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cart-seller-info h4 {
  max-width: 200px;
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
  line-height: 25px;
  // Media querys para cortas las palabras cuando son muy largas
  @include from(360px) {
    max-width: 210px;
  }
  @include from(390px) {
    max-width: 250px;
  }
  @include from(440px) {
    max-width: 290px;
  }
  @include from(490px) {
    max-width: 310px;
  }
  @include from(530px) {
    max-width: 350px;
  }
  @include from(580px) {
    max-width: 370px;
  }
  @include from(630px) {
    max-width: 480px;
  }
  @include from(680px) {
    max-width: 520px;
  }
  @include from(715px) {
    max-width: 560px;
  }
  @include from(820px) {
    max-width: 610px;
  }
  @include from(880px) {
    max-width: 680px;
  }
  @include from(920px) {
    max-width: 700px;
  }
  @include from(960px) {
    max-width: 740px;
  }
  @include from(990px) {
    max-width: 760px;
  }
  @include from(1024px) {
    max-width: 800px;
  }
  @include from(1050px) {
    max-width: 820px;
  }
  @include from(1100px) {
    max-width: 980px;
  }
  
  @include from(m) {
    // max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.enlace-whatsapp {
  font-size: var(--body-font-size);
  color: black;
  &:hover {
    color: black;
  }
}
.whatsapp-name-user {
  color: $secondColor;
}
.whatsapp-name {
  color: #0DC143;
  svg {
    color: #0DC143;
    margin-top: -4px; 
    margin-left: 5px; 
    width: 20px;
    height: 20px;
  }
}
