@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.category-box {
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;  
  max-width: 215px;
  max-height: 215px;
  @include from(910px) {
    max-height: 150px;
  }
  min-width: 100px;
  min-height: 100px;
  text-align: center;
}

.category-icon {
  transition: all .2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.20);
    transition: all .2s;
  }
  width: 100%;
  @include from(m) {
    width:80%;
  }
}
.category-img {
  min-width: 100px;
  min-height: 100px;
  width: 100%;
  height: auto;
  max-width: 115px;
  max-height: 115px;
  @include from(880px) {
    max-width: 125px;
    max-height: 125px;
  }
  margin-left: auto;
  margin-right: auto;
  
}
.category-name {
  position: relative;
  top: -10px;
  width: 100%;
  font-size: var(--small-font-size) !important;
  line-height: 15px;
  @include from(m) {
    font-size: var(--body-font-size) !important;
    top: -15px;
  }
  color: black;
}
