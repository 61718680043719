@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
// .profile-container {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   @include from(m) {
//     margin-left: 256px; // Del tamaño del menu vertical
//   }
// }
