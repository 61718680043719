@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.category-filter-title {
    text-align: center;
    color: white;
    font-size: 1rem;
    padding-top: 1.5rem;
}
.category-filter-container {
    width: 100%;
    // background: linear-gradient(to right,rgba(39,125,193,1) 20%, rgba(159,197,31,05) 110%);
    background: white;
    position: relative;
    padding: 0 0 1.5rem;
    @include from(880px) {
      padding: 0;
    }
    // @include from(1000px) {
    //   padding: 0;
    // }
}

.categories-container {
  position: relative;
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
}

.category-name {
  font-size: 1rem !important;
  @include from(m) {
    font-size: 1.1rem !important;
  }
}
// Estilos del carrousel
.slick-prev:before, .slick-next:before {
  opacity: .95 !important;
  font-size: 20px !important;
  color: white !important;
  z-index: $z-fixed;
  @include from(m) {
    font-size: 30px !important;
  }
  @include from(l) {
    font-size: 35px !important;
  }
}

.slick-prev {
  left: 0.01em !important;
  @include from(880px) {
    left: 1em !important;
  }
  z-index: $z-normal;
}
.slick-next {
  right: 0.01em !important;
  @include from(880px) {
    right: 0 !important;
  }
}

.slick-dots {
  background: transparent !important;
  padding-bottom: 10px !important;
  // @include from(1000px) {
  //   display:none !important;
  // }
}

.slick-dots li {
  button {
    &::before {
      font-size: .7rem !important;
      color: black !important;
      margin-bottom: 20px !important;
    }
  }
}

