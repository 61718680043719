@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.container-favorites {
  margin-top: 1.5rem;
}
.container-favorite {
  width: 100%;
  padding: 1rem;
  display: grid;
  div {
    border-radius: 10px !important;
    background-color: transparent !important;
  }
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
    @include from(426px) {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
    @include from(720px) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  // grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  // @include from(426px) {
  //   grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  // }
  // @include from(720px) {
  //   grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // }
  grid-template-rows: auto;
  justify-content: center;
  grid-gap: .5rem;
}

.box-favorites-empty {
  margin-top: 1.5rem;
  padding: 2rem;
}

.title-favorites {
  padding-top: 2rem;
}
// .container-favorite {
//   margin-top: 1.5rem;
//   padding: 1rem;
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
//   @include from(400px) {
//     grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//   }
//   grid-template-rows: auto;
//   grid-gap: 1rem;
// }
