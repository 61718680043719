@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.address-form {
  margin-top: 1.5rem;
  padding: 2rem;
}

// .input-phone {

// }
