@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-emptyCart {
  width: 100%;
  max-width: $max-width;
  margin: 2rem auto 0;
  text-align: center;
  padding: 2rem;
  h2 {
    color: $secondColor;
    text-align: center;
  }
}
