@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-header .ant-dropdown-trigger {
  margin-right: 1rem;
}
.avatar-show-movil {
  @include from(830px){
    display: none;
  }
}
.avatar-show-desktop {
  position: static;
  // display: none;
  @include from(830px){
    display:block;
  }
}

.drawer-show-profile {
  display:block;
  @include from(830px){
    display:none;
  }
}

.avatar-show-profile-desktop {
  display:none;
  // opacity: 0;
  // overflow: hidden;
  // background-color: pink;
  @include from(830px){
    display:block;
  }
}
.margin-header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $headerHeight; 
  z-index: 10; //Corregir Importante
  background-color: white;
}
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: $headerHeight; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: $z-fixed;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}
.ant-avatar-square {
  border-radius: 50%;
}
.margin-after {
  width: 100%;
  height: $headerHeight;
  background-color: white;
}
.main-header-p1 {
  margin-left: 1rem;
}
.main-header-p2 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main-header-p2-item {
  position: relative;
  // margin-left: 1rem;
  margin-right: .5rem;
  // margin-left: .5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-bell {
  font-size: 1.2rem;
  color: $iconColor;
}

.main-header-down {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  color: $iconColor;
}
.main-header-input-search {
  @include from(m) {
    width: 300px;
  }
  @include from(l) {
    width: 500px;
  }
  border-radius: $border-radius-input !important;
  svg {
    color: $iconColor;
  }
}

.main-header--logoM {
  width: 60px;
  height: 60px;
}
.main-header--logoD {
  width: 130px;
  height: 50px;
}
.img2 {
  display: none;
  @include from(580px) {
    display: block;
  }
}
.img1 {
  display: block;
  @include from(580px) {
    display: none;
  }
}


// Notifiaciones 

.main-container-drawer-notification {
  .ant-drawer-body {
    padding: 0 !important;
  }
}
// Menu desplegable 
.avatar-menu .ant-dropdown-menu {
  position: absolute;
  width: 200px;
  top: 35px;
  min-height: 50px;
  right: 20px;
  z-index: $z-fixed;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  & li {
    border-radius: 10px;
  }
  &::before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    left: 78%;
    border-radius: 2px;
    z-index: $z-normal;
    border-bottom: 10px solid #f2f2f2;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
.goback-btn {
  position: absolute;
  top: 80px;
  left: 25px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


// 
.avatar-menu {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  svg {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
  position: relative;
}

.ant-dropdown-menu {
  position: absolute;
  width: 200px;
  top: 50px;
  right: 0;
  z-index: $z-fixed;
}

.header-cta svg{
  color: rgb(41, 37, 37);
  font-size: 1rem;
  margin-right: 5px;
  vertical-align: center;
  margin-top: -5px;
}

.goback-btn-mobile {
  display: none;
}
.anticon {
  vertical-align: middle;
}

@media (max-width: 500px) {
  .goback-btn-desktop {
    display: none;
  }
  .goback-btn-mobile {
    display: block;
    svg {
      display:flex;
      justify-content: center;
      align-items: center;
    }
    left: 30px;
  }
}

// Banderas para el select

.main-container-icon-language {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-left: 8px;
  cursor: pointer;
  // position: relative !important;
}

.container-icon-language {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3rem;
  height: 1.3rem;
  padding: .3rem;
  // background-color: red;
  &::before {
    min-width: 1.2rem;
    min-height: 1.2rem;
    background-image: url('../../../assets/Icon ionic-ios-globe.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 2px !important;
    background-position: center;
  }
}
.ant-dropdown  {
  ul {
    // position: fixed !important;
    // margin-top: 3rem;
    // margin-right: 4rem;
    border-radius: $border-radius-input !important;
  }
  
  li {
    border-radius: $border-radius-input !important;
  }
}

.main-header .ant-dropdown-trigger {
  margin-right: 0 !important;
}

// Oculto de icono qu trae por defecto el downdow
.container-icon-language .anticon-down{
   display: none !important;
}

.flag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  
}

.flag::before{
  content: '';
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.flag.spain::before{
  background-image: url('../../../assets/propt1-esp-flag.svg');
}
.flag.cat::before{
  background-image: url('../../../assets/propt1-cat-flag.svg');
}
