@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.not-calification {
  width: 100%;
  margin: 1.5rem auto 0;
  max-width: $max-width;
  padding: 3rem 1.5rem;
}

.container-reputations {
  margin: 1.5rem auto 0;
}

.title-reputations {
  padding-top: 2rem;
}
.main-reputations-container {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill,(minmax(200px,1fr)));
  justify-content: center;
  grid-gap: 1rem;
}

.main-reputation-item {
  width: 100%;
  display: flex;
  transition: height .8s;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: .8rem .5rem;
  background-color: white;
  border-radius:$border-radius-box;
  border: 1px solid rgba(0,0,0,0.1);

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  }
  
  .main-reputation-content {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: height .8s;
    padding: 0 .8rem;
    margin-top: .5rem;
    min-height: 130px;
  }

  p {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .main-reputation-name {
    font-size: var(--body-font-size);
    color: $secondColor;
  }

  .main-reputation-date {
    font-size: var(--small-font-size);
    // margin-top: .2rem;
    // margin-bottom: .2rem;
  }


  .main-reputation-date-star {
    svg {
      font-size: 1rem !important;
    }
  }
  .main-reputation-comment {
    // min-height: 50px;
    font-size: var(--body-font-size);
    span {
      color: $secondColor;
    }
  }
}

.main-reputation-img {
  width: 100%;
  height: 100%;
  display: block;
  max-width: 90px;
  max-height: 90px;
  border-radius: 50%;
}
