.form-edit-email-container {
  margin-top: 1.5rem;
}
.form-edit-email {
  width: 100%;
  // margin: 20px auto 30px auto;
  // box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
  padding: 2rem 1.5rem;
  border-radius: 20px;
  margin: 0 auto;
  background-color: white;
}
