@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.main-container-card-grid {
  // background-color: red;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
}

.main-container-card-grid--store-name {
  color: $secondColor;
}
.main-container-card-item {
  // background-color: yellow;
  margin-top: 10px;
  padding: 1rem .7rem;
  @include from(720px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
  }
}


.main-container-card-item-p1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  @include from(720px) {
    // width: 35%;
    justify-content: space-around;
    padding-left: 3rem;
    padding-right: 0;
  }
}

.main-container-card-description-title {
  min-width: 100px;
  width: auto;
  // background-color: yellow;
  text-align: left;
}
.main-container-card-description-data {
  font-size: var(--small-font-size);
  margin-bottom: 0;
  margin-left: 15px;
}
.main-container-card-description-title {
  color: $secondColor;
  max-width: 160px;
  text-align-last: left;
  font-size: var(--body-font-size);
  margin-bottom: 0;
  margin-left: 15px;
  // background-color: red;
  @include from(420px) {
    max-width: 220px;
  }
  @include from(620px) {
    max-width: 280px;
  }
  @include from(720px) {
    max-width: 120px;
  }
  @include from(920px) {
    max-width: 150px;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.main-container-cart-img {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
  margin-right: 10px;
  border: 1px solid rgba(0,0,0,.05);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
    object-position: center;
  }
}

.main-container-card-item-p2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
  @include from(720px) {
    width: 65%;
  }
}

.main-container-card-item-p2-item {
  
  // background-color: green;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include from(720px) {
    justify-content: center;
      &:nth-child(1n) {
        width: 50%;
      }
      &:nth-child(2n) {
        width: 30%;
      }
      &:nth-child(3n) {
        width: 20%;
      }
  }

}

.main-container-card-item-delete {
  cursor: pointer;
  svg {
    color: rgb(184, 53, 53) !important;
    transition: all .6s;
    &:hover {
      margin-top: -2px;
      transition: all .6s;
      color: rgb(184, 39, 39) !important;
    }
    width: 19px !important;
    height: 19px !important;
    @include from(m) {
      width: 22px !important;
      height: 22px !important;
    }
    @include from(720px) {
      width: 25px !important;
      height: 25px !important;
    }
  }
}

.main-container-card-item-subtotal {
  margin-bottom: 0;
}

.container-editQuiantity {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-container-editQuiantity {
    
    &.MinusOutlined {
      border-top-left-radius: $border-radius-box !important;
      border-bottom-left-radius: $border-radius-box !important;
    }
    &.PlusOutlined {
      border-top-right-radius: $border-radius-box !important;
      border-bottom-right-radius: $border-radius-box !important;
    }

    height: 30px !important;
    max-height: 30px !important;
    padding: 0 13px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(0,0,0,.15);
    }
    border: 1px solid rgba(0,0,0,.05);
    span {
      svg {
        width: 13px !important;
        height: 13px !important;
        color: $secondColor;
      }
    }
  }
  .container-editQuiantity-quantity {
    height: 30px !important;
    max-height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .6rem;
    color: $secondColor;
    border-top: 1px solid rgba(0,0,0,.05);
    border-bottom: 1px solid rgba(0,0,0,.05);
    font-size: var(--body-font-size);
  }
}

.main-container-card-grid-total {
  width: 100%;
  text-align: right;
  margin-top: .8rem;
  padding-right: .5rem;
}

.main-container-card-grid-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem;
  @include from(720px) {
    justify-content: flex-end;
    button {
      margin-left: .5rem;
    }
  }
}

