// Mixin para las media queries.
@mixin from($bp) {
    @if map-has-key($breakPoints, $bp) {
        @media screen and(min-width: map-get($breakPoints, $bp)) {
            @content;
        }
    }@else {
        @media screen and(min-width: $bp) {
            @content;
        } 
    }
}
