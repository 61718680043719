@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.seller-img-container {
    width: 100%;
    max-width: 750px;
    position: relative;
    margin-bottom: 100px;
}
.seller-info-img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
}
.seller-info-portrait-img {
    width: 100%;
    max-width: $max-width;
	max-height: 200px;
}
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 1rem auto;
    padding: 1.5rem;
    background-color: white;
}
.buttons-container {
    display: flex;
    width: 100%;
	max-width: 350px;
    justify-content: space-around;
    align-items: center !important;
	flex-wrap: wrap;
	margin-top: 0;
	margin-bottom: 0;
    align-items: flex-start;
}
.btn-store {
	margin: 5px auto
}
.more-info {
    margin-top: 10px;
    width: 100%;
    @include from(m) {
      width: 95%;
    }
    text-align: center;
    background-color: transparent;
    border: 1px solid #d9d9d9 !important;
    overflow: hidden;
}
.more-info-panel {
    border: 0 !important;
}

.more-info-panel-container {
  @include from(m) {
    display: flex;
    justify-content: space-between;
    align-items: center ;
  }
  @include from(880px) {
    display: flex;
    justify-content: space-around;
    align-items: center ;
  }
}

.more-info-panel-p1, .more-info-panel-p2 {
  text-align: left;
}
.more-info-panel-text {
  svg {
    color: $secondColor;
    margin-right: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center ;
  }
}

.hover-blue:hover {
    background-color: $secondColor;
    color: white;
}
.more-info-bold {
    font-weight: 600;
}
@media (max-width: 650px) {
    .seller-img-container {
        margin-bottom: 100px;
    }
    
    .seller-info-img {
        height: 150px;
        width: 150px;
    }
}

.seller-profile-info {
    margin-top: 0;
	margin-bottom: 0;
}

@media (max-width: 500px) {
    .seller-img-container {
        margin-bottom: 75px;
    }
    .seller-info-img {
        height: 100px;
        width: 100px;
    }

}
