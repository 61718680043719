@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";


// .card-seller-favorites {
//   // border-radius: 10px;
//   // max-width: 300px;
// }

.card-seller-favorites-description {
  padding: .7rem;
 // min-height: 140px;
  text-align: center;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
}

.blue-text {
    color: $secondColor;
}
.gray-text {
    color: gray;
}
.main-text {
    color: $textColor;
}
.seller-btn {
    color: $firstColor;
    border: 1px solid $firstColor;
}

// .seller-img-favorites {
//   width: 100%;
//   // max-width: 200px;
//   min-height: 200px;
//   max-height: 200px;
//   border-radius: 30px;
//   margin-bottom: 10px;
//   object-fit: contain !important;
//   object-position: center !important;
//   @include from(500px) {
//     min-height: 165px;
//     max-height: 165px;
//   }

// }

.container-rate-favorites {
  width: 100%;
  // background: blue;container-seller-description-favorites
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center !important;
  svg {
    width: 13px !important;
    height: 13px !important;
  }
}

.seller-img-favorites {
    width: 100%;
    // min-width: 100%;
    // max-width: 120px;
    min-height: 85px;
    max-height: 85px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    // border-radius: 21px;
    margin-bottom: 10px;
    margin-right: auto;
    object-fit: contain !important;
    object-position: center;
    margin-left: auto;
    text-align: center;
    // @include from(371px) {
    //   width: 100%;
    //   // max-width: 250px;
    //   min-height: 150px;
    //   max-height: 150px;
    // }
    @include from(426px) {
      width: 100%;
      // max-width: 150px;
      min-height: 120px;
      max-height: 120px;
    }
    
    @include from(725px) {
      width: 100%;
      // max-width: 165px;
      min-height: 140px;
      max-height: 140px;
    }
  
}

.btn-removeFavorite-profile {
  margin-top: .2rem
}
