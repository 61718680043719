@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.seller-store-container {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

.not-found-store {
  margin-top: 1.5rem;
}
